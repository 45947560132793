import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"mt-1"},[_c(VLayout,{staticClass:"memberid-primary--text font-weight-bold",attrs:{"justify-center":""}},[_vm._v(" ご加入ありがとうございます！ ")]),(_vm.showName)?_c(VLayout,{attrs:{"justify-center":""}},[_vm._v(" "+_vm._s(this.fullName)+"様の組合員番号は ")]):_c(VLayout,{attrs:{"justify-center":""}},[_vm._v(" 組合員番号は ")]),_c(VLayout,{staticClass:"rounded-lg memberid-black-100 pa-4 my-3 text-h6 font-weight-bold",attrs:{"justify-space-between":""}},[_c(VLayout,{attrs:{"justify-center":""}},[_vm._v(_vm._s(this.memberCode))]),(_vm.canCopyToClipboard)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard()}}},[_c(VIcon,[_vm._v("mdi-content-copy")])],1):_vm._e()],1),_c(VSheet,{staticClass:"rounded-lg red lighten-5 pa-4"},[_c(VLayout,{staticClass:"memberid-red--text text-h5 font-weight-bold pa-1",attrs:{"justify-center":""}},[_vm._v(" メモをとるか、画面キャプチャで保存をお願いします！ ")]),_c(VLayout,{staticClass:"memberid-red--text pa-1",attrs:{"justify-center":""}},[_vm._v(" 上記の組合員番号は、アプリのご利用に必要になります。 ")]),_c(VLayout,{attrs:{"justify-end":""}},[_c(VImg,{staticClass:"mt-n8 mb-n4 mr-n4",attrs:{"src":require('@/assets/todok-backgroundimage.png'),"max-width":"60"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }