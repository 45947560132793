import AbstractApiRequest from '@/api/apimodel/abstract-api-request'

export default class ReserveMemberRequest extends AbstractApiRequest {
  // 8桁
  memberCode = ''
  // 13桁
  memberCardId = ''
  lastName = ''
  firstName = ''
  lastNameKana = ''
  firstNameKana = ''
  sexuality = 0
  zipCode = ''
  address = ''
  tel = ''
  mailAddress = ''
  birthday = ''
  contributionAmount = 0
  actualContributionAmount = 0
  selectedPay = ''
  accessToken = ''
  idToken = ''
  refreshToken = ''
  delivery = null
}
