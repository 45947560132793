export default class DeliveryInformation {
  // 別の住所
  selectedAddress = null

  zipCode = ''
  addressList = []
  address1 = ''
  address2 = ''
  address3 = ''

  // 別の電話番号
  selectedTel = null
  phoneNumber = ''

  // お住まい
  selectedResidence = null

  // 置き配時の置き場所
  selectedPlacement = null
  placementOtherInput = ''

  // お届け時の確認
  selectedConfirmationOfDelivery = null

  // カタログ・納品書等一式の配付申込
  selectedDeliveryOfPaperCatalog = null

  // トドックおためしキャンペーンの申込
  selectedTodokTrialCampaign = null

  // 紹介
  selectedIntroducer = null
  introducerName = ''
  introducerUnionNumber = ''
  introducerPhoneNumber = ''

  // メールアドレス
  email = ''

  formatZipCode() {
    if (this.zipCode.length === 0) return
    return this.zipCode.slice(0, 3) + '-' + this.zipCode.slice(3)
  }
}
