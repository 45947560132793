<template>
  <div v-if="isShow">
    <v-row class="mx-4 mt-2" v-for="(message, i) in messages" :key="i" :value="i">
      <p class="body-2 memberid-red--text font-weight-bold">・{{ message }}</p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessageListComponent',
  props: {
    isShow: { type: Boolean },
    messages: { type: Array },
  },
  mounted() {},
}
</script>
