import LoadingOverlay from '@/plugins/LoadingOverlay'
import Snackbar from '@/plugins/Snackbar'
import ErrorMessage from '@/data-models/error-message'
import Vue from 'vue'
import store from '@/store'

const LOADING_OVERLAY_HOLDER_ID = 'memberidLoadingOverlay'
const SNACKBAR_HOLDER_ID = 'memberidSnackbar'

// TODO エラーのスナックバーの表示内容は？
const EXCEPTION_ERROR_TITLE = 'システムエラー'
const EXCEPTION_ERROR_MESSAGE = 'お問い合わせフォーム'
//TODO お問い合わせのフォームのURLは？
const EXCEPTION_ERROR_LINK = 'https://reg18.smp.ne.jp/regist/is?SMPFORM=tem-tdpgr-55dbd1a06dca12720344f6adcd41bf12'

const DefaultSnackObject = {
  title: EXCEPTION_ERROR_TITLE,
  message: EXCEPTION_ERROR_MESSAGE,
  link: EXCEPTION_ERROR_LINK,
}

const startLoadingFunction = () => {
  prepareCheck()
  MemberidFrontendPlugin.loadingInstance.loading = true
}
const endLoadingFunction = () => {
  prepareCheck()
  MemberidFrontendPlugin.loadingInstance.loading = false
}

const showSnackbarFunction = snackObj => {
  prepareCheck()
  // titleとmessageは必須なので、そのチェックをしてから表示の判断をする
  if (snackObj) {
    const title = snackObj.title
    if (title) {
      const message = snackObj.message
      if (message) {
        MemberidFrontendPlugin.snackbarInstance.enable = true
        MemberidFrontendPlugin.snackbarInstance.title = snackObj.title
        MemberidFrontendPlugin.snackbarInstance.message = snackObj.message
        const link = snackObj.link
        if (link) {
          MemberidFrontendPlugin.snackbarInstance.link = snackObj.link
        }

        const errorMode = snackObj.errorMode
        if (errorMode !== undefined) {
          MemberidFrontendPlugin.snackbarInstance.errorMode = errorMode
        } else {
          MemberidFrontendPlugin.snackbarInstance.errorMode = true
        }
      }
    }
  }
}

const getSnackObjectByErrorResponse = errorResponse => {
  prepareCheck()
  let snackObj = null
  const data = errorResponse.data
  if (data) {
    console.error(data)
    const errorCode = data.error_status // 基幹APIサーバーの標準エラーコード
    snackObj = store.getters.errorMessages.find(err => err.code === errorCode)
    if (!snackObj) {
      const errorMessage = data.error_message
      if (errorMessage) {
        snackObj = { title: EXCEPTION_ERROR_TITLE, message: errorMessage }
      } else {
        snackObj = DefaultSnackObject
      }
    }
  }
  return snackObj
}

const $showErrorSnackbarFunction = error => {
  prepareCheck()
  const pathname = location.pathname
  if (!error) return
  if (pathname === '/entrance') {
    const errorStatus = error.response.data.error_status
    if (ErrorMessage.isRegistrationError(errorStatus)) {
      return
    }
  }
  let snackObj = null
  if (error.response) {
    // 渡された引数がaxios由来のerrorオブジェクトだった場合
    snackObj = getSnackObjectByErrorResponse(error.response)
  } else {
    // 渡された引数文字列だった場合、エラーコードを想定して表示
    const errorCode = error.toString()
    snackObj = store.getters.errorMessages.find(err => err.code === errorCode)
    if (!snackObj) {
      snackObj = DefaultSnackObject
    }
  }

  showSnackbarFunction(snackObj)
}

const closeSnackbarFunction = () => {
  prepareCheck()
  MemberidFrontendPlugin.snackbarInstance.enable = false
  MemberidFrontendPlugin.snackbarInstance.title = ''
  MemberidFrontendPlugin.snackbarInstance.message = ''
  MemberidFrontendPlugin.snackbarInstance.link = ''
}

const prepareCheck = () => {
  if (!MemberidFrontendPlugin.prepared) {
    MemberidFrontendPlugin.prepare()
  }
}

const MemberidFrontendPlugin = {
  loadingInstance: null,
  snackbarInstance: null,
  // popupNotificationInstance: null,
  prepared: false,
  install(Vue) {
    Vue.component(LoadingOverlay)
    Vue.$startLoading = startLoadingFunction
    Vue.$endLoading = endLoadingFunction
    Vue.prototype.$startLoading = startLoadingFunction
    Vue.prototype.$endLoading = endLoadingFunction

    Vue.component(Snackbar)
    Vue.$showSnackbar = showSnackbarFunction
    Vue.$closeSnackbar = closeSnackbarFunction
    Vue.$showErrorSnackbar = $showErrorSnackbarFunction
    Vue.prototype.$showSnackbar = showSnackbarFunction
    Vue.prototype.$closeSnackbar = closeSnackbarFunction
    Vue.prototype.$showErrorSnackbar = $showErrorSnackbarFunction

    // Vue.component(PopupNotification)
    // Vue.$showPopupNotification = showPopupNotificationFuntion
    // Vue.$showCartAddedNotification = showCartAddedNotificationFunction
    // Vue.prototype.$showPopupNotification = showPopupNotificationFuntion
    // Vue.prototype.$showCartAddedNotification = showCartAddedNotificationFunction
  },
  prepare() {
    let holder = document.getElementById(LOADING_OVERLAY_HOLDER_ID)
    if (!holder) {
      const app = document.getElementById('app')
      holder = document.createElement('div')
      holder.id = LOADING_OVERLAY_HOLDER_ID
      app.appendChild(holder)
    }
    const LOClass = Vue.extend(LoadingOverlay)
    this.loadingInstance = new LOClass({ propsData: { loading: false } })
    this.loadingInstance.$mount('#' + LOADING_OVERLAY_HOLDER_ID)

    holder = document.getElementById(SNACKBAR_HOLDER_ID)
    if (!holder) {
      const app = document.getElementById('app')
      holder = document.createElement('div')
      holder.id = SNACKBAR_HOLDER_ID
      app.appendChild(holder)
    }
    const SnackbarClass = Vue.extend(Snackbar)
    this.snackbarInstance = new SnackbarClass({ propsData: { enable: false, title: '' } })
    this.snackbarInstance.$mount('#' + SNACKBAR_HOLDER_ID)

    // holder = document.getElementById(POPUP_OVERLAY_HOLDER_ID)
    // if (!holder) {
    //   const app = document.getElementById('app')
    //   holder = document.createElement('div')
    //   holder.id = POPUP_OVERLAY_HOLDER_ID
    //   app.appendChild(holder)
    // }
    // const PopupNotificationClass = Vue.extend(PopupNotification)
    // this.popupNotificationInstance = new PopupNotificationClass({ propsData: { overlay: false, message: '' } })
    // this.popupNotificationInstance.$mount('#' + POPUP_OVERLAY_HOLDER_ID)
    this.prepared = true
  },
}

export default MemberidFrontendPlugin
