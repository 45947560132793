<template>
  <div>
    <h4 class="memberid-black-600--text subtitle-1 font-weight-bold pt-0 mb-2">
      {{ title }}
      <span v-if="requiredFlag" class="memberid-primary py-1 px-2 memberid-white--text font-weight-normal rounded-lg"
        >必須</span
      >
    </h4>
    <p class="mb-0 memberid-black-500--text subtitle-2 text-indention">
      {{ caption }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    requiredFlag: {
      type: Boolean,
      default: false,
    },
    caption: {
      type: String,
      required: false,
    },
  },
}
</script>
<style scoped>
  .text-indention {
    white-space: pre-line!important;
  }
</style>
