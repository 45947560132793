export default class PaymemtServiceConfigForCc {
  shopId = '' // ショップID
  shopPass= '' // ショップパスワード
  entryTranUrl = '' //取引登録用のURL
  execTranUrl = '' // 決済実行用のURL
  alterTranUrl = '' // 決済変更用のURL

  static fromApiResponse(data) {
    let pscfcc = new PaymemtServiceConfigForCc()
    pscfcc.shopId = data.shopId
    pscfcc.shopPass = data.shopPass
    pscfcc.entryTranUrl = data.entryTranUrl
    pscfcc.execTranUrl = data.execTranUrl
    pscfcc.alterTranUrl = data.alterTranUrl

    return pscfcc
  }
}
