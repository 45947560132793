<template>
  <div class="pc-wrap memberid-signup-main" :class="{ 'xs-wrap': !isMobile, 'mt-2': !isMobile }" v-resize="onResize">
    <v-container class="mt-1 mb-10">
      <h3 class="memberid-black-600--text title font-weight-bold">口座登録に失敗しました</h3>
      <p class="mt-2">
        誠に恐れ入りますが、システムエラーが発生し、口座登録に失敗しました。お手数おかけしますが、再度口座登録のお手続きをお願いいたします。
      </p>
      <p class="mb-0 memberid-black-500--text subtitle-2">
        ※何度もエラーが発生する場合は、しばらく時間をおいて組合員加入のお手続きを最初からやり直してください。
      </p>
    </v-container>
    <rounded-button buttonText="口座登録前の画面に戻る" :nextPage="nextPage" :isdisabled="false" />
  </div>
</template>
<script>
import RoundedButton from '@/components/RoundedButtonComponent'
import storage from '@/storage'
import store from '@/store'
import CommonMixin from '@/components/mixins/common-mixin'

export default {
  name: 'AccountTransferComplete',
  components: {
    RoundedButton,
  },
  mixins: [CommonMixin],
  data() {
    return {}
  },
  async mounted() {
    storage.removeAccountTransferStatus()
  },
  methods: {
    nextPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'CapitalEntry' })
    },
  },
}
</script>
