import { getInstance } from './authWrapper'
import StringUtil from '@/lib/string-util'
import storage from '@/storage'

export const authGuard = (to, from, next) => {
  const authService = getInstance()

  const fn = async () => {
    if (authService.isAuthenticated) {
      if (to.name === 'MemberEntry') {
        const claims = await authService.getIdTokenClaims().catch(e => {
          console.log('get id token error ', e)
        })
        if (!claims) {
          // 手でlocalStorageを削除すると戻れないのでログインするように設定
          authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
        }
        const memberCode = (claims['https://member.sapporo.coop/app_metadata'] || {})['memberCode']
        // console.log('memberCode', memberCode)
        if (memberCode) {
          const member12 = memberCode + '1000'
          const memberCardId = member12 + StringUtil.calcCheckDigit2(member12)

          storage.setCompMemberCode(memberCardId)

          authService.logout({ returnTo: window.location.origin + '/complete' })
        }
      }
      return next()
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  if (!authService.loading) {
    return fn()
  }

  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn()
    }
  })
}
