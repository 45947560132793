import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{staticClass:"mt-1"}),_c(VDialog,{attrs:{"persistent":"","width":"500"},model:{value:(_vm.ieDialog),callback:function ($$v) {_vm.ieDialog=$$v},expression:"ieDialog"}},[_c(VCard,{staticClass:"pa-4"},[_c('h4',{staticClass:"memberid-black-600--text subtitle-1 font-weight-bold mb-2"},[_vm._v(" お使いのブラウザはサポートされていません ")]),_c('p',{staticClass:"memberid-black-400--text subtitle-2 mb-10"},[_vm._v(" 大変申し訳ございませんが、現在ご利用のブラウザはコープさっぽろの組合員加入ではサポートしておりません。"),_c('br'),_vm._v(" 安全にサービスをご利用いただくために対応するブラウザをお使いください。 ")]),_c(VLayout,{staticClass:"mb-6",attrs:{"justify-center":""}},[_c(VBtn,{staticClass:"memberid-primary rounded-pill memberid-white--text font-weight-bold subtitle-1",attrs:{"height":"52","width":"90%"},on:{"click":function($event){_vm.ieDialog = false
            _vm.historyBack()}}},[_vm._v("戻る")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }