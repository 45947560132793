import memberidDataClient from '@/api/memberid-data-client'
import ErrorMessage from '@/data-models/error-message'
import router from '@/router'
import storage from '@/storage'
import store from '@/store'
import MutationTypes from '@/store/mutation-types'

const consoleError = (error) => {
  if (error.data) {
    console.error(error.data)
  }
}

const state = {
  errorMessages: [],
  from: '',
}

const getters = {
  errorMessages: (state) => state.errorMessages,
  from: (state) => state.from,
}

const mutations = {
  [MutationTypes.SET_ERROR_MESSAGES](state, errorMessages) {
    state.errorMessages = errorMessages
  },
  [MutationTypes.SET_FROM](state, from) {
    state.from = from
  },
}

const actions = {
  // 初期化
  initErrorMessages() {
    store.dispatch('getErrorMessagesFromJson')
  },

  getErrorMessagesFromJson({ commit }) {
    memberidDataClient
      .getErrorMessageData()
      .then((response) => {
        const items = response.data
        let errorMessages = []
        items.forEach((item) => {
          errorMessages.push(ErrorMessage.fromObject(item))
        })
        commit(MutationTypes.SET_ERROR_MESSAGES, errorMessages)
      })
      .catch((error) => {
        consoleError(error)
      })
  },

  setFrom({ commit }, from) {
    storage.setFrom(from)
    commit(MutationTypes.SET_FROM, from)
  },
  routerPushWithFromQuery({ state }, { name, params, query = {} }) {
    const withFromQuery = query
    if (state.from) {
      withFromQuery.from = state.from
    }
    router.push({ name: name, params: params, query: withFromQuery })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
