<template>
  <center class="pc-wrap memberid-black-600--text">
    <h3 class="mt-16 mb-10 title font-weight-bold">ただいまメンテナンス中です</h3>
    <div class="maintenance-datetime-area memberid-black-100 text-left pa-3">
      <span class="font-weight-bold title-1">メンテナンス日時 </span><br />
      <span class="font-weight-medium variable-text">{{ maintenanceDateTime }}</span
      ><br />
      <span class="caption">※時間は多少前後することがございます。</span>
    </div>
    <p class="mt-8 text-left subtitle-2">
      <span class="variable-text">{{ description }}</span>
    </p>
    <div class="mt-15">
      <a link class="text-decoration-underline memberid-black-600--text" href="https://www.sapporo.coop/"
        >コープさっぽろTOPに戻る</a
      >
    </div>
  </center>
</template>
<script>
import memberidApiClient from '@/api/memberid-api-client'

export default {
  name: 'Maintenance',
  data() {
    return {
      maintenanceDateTime: '未定',
      description:
        '現在、システムメンテナンス中のため、組合員加入の受付を一時停止しております。\nご不便をお掛けいたしますが、ご理解いただきますようお願い申し上げます。',
    }
  },
  mounted() {
    memberidApiClient
      .getMaintenanceInfo()
      .then(response => {
        const maintenanceInfo = response.data.maintenanceInfo
        if (maintenanceInfo && maintenanceInfo.maintenanceDateTime && maintenanceInfo.description) {
          this.maintenanceDateTime = maintenanceInfo.maintenanceDateTime
          this.description = maintenanceInfo.description
        }
      })
      .catch(error => {
        // console.log('getMaintenanceInfo error')
        console.log(error)
      })
  },
}
</script>

<style scoped>
.maintenance-datetime-area {
  border-radius: 6px;
  line-height: 160%;
}
.variable-text {
  white-space: pre-wrap;
}
</style>
