import MemberEntrance from '@/views/MemberEntrance'
import MemberEntry from '@/views/MemberEntry'
import DeliveryInformation from '@/views/DeliveryInformation'
import ChildInformation from '@/views/ChildInformation'
import CapitalEntry from '@/views/CapitalEntry'
import AccountTransferComplete from '@/views/AccountTransferComplete'
import AccountTransferError from '@/views/AccountTransferError'
import MemberConfirm from '@/views/MemberConfirm'
import MemberEntryComplete from '@/views/MemberEntryComplete'
import Maintenance from '@/views/Maintenance'
import DeliveryMemberComplete from '@/views/DeliveryMemberComplete'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from './auth'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'MemberEntrance',
    component: MemberEntrance,
    meta: {
      requiredAuth: false,
      title: 'ログイン｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/entry',
    name: 'MemberEntry',
    component: MemberEntry,
    beforeEnter: authGuard,
    meta: {
      requiredAuth: true,
      title: '組合員情報の入力｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/delivery',
    name: 'DeliveryInformation',
    component: DeliveryInformation,
    beforeEnter: authGuard,
    meta: {
      requiredAuth: true,
      title: '配送先情報の入力｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/child',
    name: 'ChildInformation',
    component: ChildInformation,
    beforeEnter: authGuard,
    meta: {
      requiredAuth: true,
      title: 'お子様情報の登録｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/capital',
    name: 'CapitalEntry',
    component: CapitalEntry,
    beforeEnter: authGuard,
    meta: {
      requiredAuth: true,
      title: '出資金のお支払い｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  // 口座登録完了画面
  {
    path: '/accountComplete',
    name: 'AccountTransferComplete',
    component: AccountTransferComplete,
    beforeEnter: authGuard,
    meta: {
      title: '口座登録完了｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  // 口座登録失敗画面
  {
    path: '/accountError',
    name: 'AccountTransferError',
    component: AccountTransferError,
    beforeEnter: authGuard,
    meta: {
      title: '口座登録エラー｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/comfirm',
    name: 'MemberConfirm',
    component: MemberConfirm,
    beforeEnter: authGuard,
    props: true,
    meta: {
      requiredAuth: true,
      title: '内容の確認｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新組合員加入ページです。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/deliveryComplete',
    name: 'MemberDeliveryComplete',
    component: DeliveryMemberComplete,
    props: true,
    meta: {
      requiredAuth: true,
      title: '組合員加入と宅配申込みありがとうございます｜コープさっぽろ',
      desc: 'コープさっぽろの組合員加入と宅配申込み、誠にありがとうございます！',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/complete',
    name: 'MemberComplete',
    component: MemberEntryComplete,
    props: true,
    meta: {
      requiredAuth: true,
      title: '組合員加入ありがとうございます｜コープさっぽろ',
      desc: 'コープさっぽろの組合員にご加入いただき、誠にありがとうございます！',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: {
      requiredAuth: false,
      title: 'メンテナンス｜新規組合員加入｜コープさっぽろ',
      desc: 'コープさっぽろの新規組合員加入は現在メンテナンス中です。',
      keywords: 'コープさっぽろ,生協,組合員,加入,ちょこっと,ポイント,トドック,宅配',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 } // トップに移動
    }
  },
})

router.requiredMoveMain = () => {
  const pathname = location.pathname
  return pathname.startsWith('/entrance')
}

export default router
