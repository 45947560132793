import AbstractApiRequest from '@/api/apimodel/abstract-api-request'

export default class SubmitClaimRequest extends AbstractApiRequest {
  // 取引ID
  accessId = ''
  // 取引パスワード
  accessPass = ''
  // 利用金額
  amount = ''
}
