<template>
  <div class="pc-wrap memberid-signup-main">
    <v-container class="mt-1">
      <h3 class="memberid-black-600--text title font-weight-medium">お子様の情報を登録</h3>
      <v-form v-model="childForm" class="mt-2">
        <radio-button
          inputTitle="お子様について"
          :placementTextList="['現在、妊娠中', '0〜15歳未満のお子様がいる']"
          :value.sync="child.selectedAboutChildren"
          caption="兄弟姉妹がいる場合は、一番下のお子様についてお答えください。"
          requiredFlag
        />
        <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
          <div v-if="isSelectChild">
            <single-valid-text-field
              :inputTitle="childname.title"
              :requiredFlag="true"
              :placeholder="childname.placeholder"
              :value.sync="child.childName"
              :errorMessages="validChildName()"
              v-on:blur="$v.child.childName.$touch()"
            />
          </div>
          <date-picker
            :placeholder="childDatePlaceholder"
            :inputTitle="childDateTitle"
            :requiredFlag="true"
            :inputDate.sync="child.childDate"
            v-on:blur="$v.child.childDate.$touch()"
            :errorMessages="validChildDate()"
            :minDateParam="minChildDate"
            :maxDateParam="maxChildDate"
          />
          <v-row class="mb-0">
            <v-col>
              <common-input-title
                :title="imageFileTitle()"
                :requiredFlag="true"
                caption="母子手帳や保険証など、お子様のお名前と生年月日がはっきりと読み取れるように撮影した画像を添付してください。"
              />
            </v-col>
          </v-row>
          <v-row align="center" class="mt-0">
            <v-col cols="12" class="pt-0 pb-0">
              <v-file-input
                accept="image/png, image/jpg, image/jpeg"
                outlined
                background-color="memberid-white"
                placeholder="クリックで画像を選択"
                color="memberid-primary"
                ref="inputImage"
                :clearable="false"
                :truncate-length="30"
                @change="selectedFile"
                @blur="$v.child.image.$touch()"
                :error-messages="imageErrors()"
                v-if="dispInputImage"
                :class="{ 'text-active': activeText }"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-layout justify-center>
            <v-sheet v-if="!isEmptyForImage" style="position: relative" flat width="220" height="auto" class="mb-2">
              <v-img :src="child.image && child.image.fileUrl" :contain="true"> </v-img>
              <v-btn
                color="todok-black-500"
                elevation="0"
                class="ml-8"
                fab
                dark
                x-small
                absolute
                top
                style="right: -15px"
                @click="removeImage()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-sheet>
          </v-layout>
          <v-layout justify-center>
            <v-dialog v-model="sampleImageDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text small color="memberid-black-400"
                  ><span class="text-decoration-underline">画像サンプル(見本)</span></v-btn
                >
              </template>
              <v-card class="px-4 pt-4">
                <v-row align="center">
                  <v-col cols="6">
                    <v-img :src="require('@/assets/mother-and-child-notebook.png')" width="100%" />
                  </v-col>
                  <v-col cols="6">
                    <v-img :src="require('@/assets/insurance-card.png')" width="100%" />
                  </v-col>
                </v-row>
                <v-sheet class="rounded-lg todok-black-100 pa-4 mt-2">
                  <h4 class="todok-black-600--text subtitle-2 font-weight-bold">
                    赤枠内のお子様の名前と生年月日、または交付日がはっきりと読める画像をお願いします。
                  </h4>
                </v-sheet>
                <v-card-actions>
                  <v-spacer />
                  <v-btn class="todok-brown--text subtitile-2 font-weight-bold" text @click="sampleImageDialog = false">
                    閉じる
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-layout>
        </v-sheet>
      </v-form>
    </v-container>
    <rounded-button :buttonText="nextButtonText" :nextPage="nextPage" :isdisabled="!childForm" />
    <v-btn
      v-if="!lastViewIsConfirm"
      text
      @click="backToPreviousPage()"
      class="px-0 mt-6 memberid-primary--text subtitle-1"
    >
      ← 前へ戻る
    </v-btn>
  </div>
</template>
<script>
import Vue from 'vue'
import ImageUtil from '@/lib/image-util'
import Constants from '@/lib/constants'
import RadioButton from '@/components/RadioButtonComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import DatePicker from '@/components/DatePickerComponent'
import SingleValidTextField from '@/components/SingleValidTextFieldComponent'
import CommonInputTitle from '@/components/InputTitle'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import store from '@/store'
import storage from '@/storage'
import ChildInfo from '@/models/child-information'

export default {
  name: 'ChildInformation',
  mixins: [validationMixin],
  components: {
    SingleValidTextField,
    DatePicker,
    RadioButton,
    RoundedButton,
    CommonInputTitle,
  },
  validations() {
    const validObj = {
      child: { childDate: { required }, image: { required } },
    }
    if (this.isSelectChild) {
      validObj.child.childName = { required }
    }
    return validObj
  },
  data() {
    return {
      childForm: true,
      lastViewIsConfirm: false,
      nextButtonText: '次へ',
      childname: {
        title: 'お子様のお名前',
        required: true,
        placeholder: '例）トドック 太郎',
      },
      selectedChild: null,
      childName: '',
      childDate: null,

      // image: null,
      dispInputImage: true,
      activeText: false,
      sampleImageDialog: false,

      child: { type: ChildInfo },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!from || from.name === null) {
      if (!storage.getMemberInfo()) {
        next({ name: 'MemberEntry' })
      }
    }
    next()
  },
  async mounted() {
    const lastview = storage.lastViewIsConfirm()
    if (lastview) {
      this.lastViewIsConfirm = true
      this.nextButtonText = '内容確認へ戻る'
    }

    this.child = new ChildInfo()
    const ci = await store.dispatch('getChildInformation')
    if (ci) {
      this.child.selectedAboutChildren = ci.selectedAboutChildren
      this.child.childDate = ci.childDate
      this.child.image = ci.image
      this.child.childName = ci.childName
    }
  },
  methods: {
    imageErrors() {
      const errors = []
      if (!this.$v.child.image.$dirty) return errors
      !this.$v.child.image.required && errors.push('画像を選択してください。')
      return errors
    },

    validate() {
      this.$v.$touch()
      return this.$v.$invalid
    },

    async nextPage() {
      if (this.validate()) {
        return
      }
      this.setDateToStore()
      if (storage.lastViewIsConfirm()) {
        const hasDelivery = await store.dispatch('hasDelivery')
        if (!hasDelivery) {
          store.dispatch('routerPushWithFromQuery', { name: 'DeliveryInformation' })
          return
        }
        // 口座なかったら
        if (!storage.isAccountTransferStatus()) {
          store.dispatch('routerPushWithFromQuery', { name: 'CapitalEntry' })
          return
        }

        // 口座も宅配も登録していれば確認画面に遷移
        store.dispatch('routerPushWithFromQuery', { name: 'MemberConfirm' })
        return
      }

      store.dispatch('routerPushWithFromQuery', { name: 'DeliveryInformation' })
    },

    backToPreviousPage() {
      this.$router.go(-1)
      // store.dispatch('routerPushWithFromQuery', { name: 'MemberEntry' })
    },

    async selectedFile(e) {
      const file = e
      if (!file) {
        this.$refs.inputImage.value = null
        return
      }
      try {
        // 圧縮した画像を取得
        const compFile = await ImageUtil.getCompressImageFileAsync(file)

        // 画像情報の設定
        // const blob = compFile
        const fileUrl = await ImageUtil.getDataUrlFromFile(compFile)
        const fileName = file.name
        this.child.image = { blob: compFile, fileUrl: fileUrl, fileName: fileName }
      } catch (err) {
        Vue.$showSnackbar({
          title: '画像のアップロードに失敗しました。',
          message: '再度アップロードをお願いいたします。',
        })
      }
    },
    removeImage() {
      this.$refs.inputImage.reset()
      this.activeText = false
      this.child.image = null
      this.dispInputImage = false
      this.$nextTick(function () {
        this.dispInputImage = true
      })
      this.$v.child.image.$touch()
    },
    imageFileTitle() {
      return this.isSelectChild ? '確認書類の画像' : '母子手帳（表紙）の画像'
    },
    validChildName() {
      const errors = []
      if (!this.isSelectChild || !this.$v.child.childName.$dirty) return errors
      !this.$v.child.childName.required && errors.push(`${this.childname.title}を入力してください`)
      return errors
    },
    validChildDate() {
      const errors = []
      if (!this.$v.child.childDate.$dirty) return errors
      const title = this.childDateTitle
      !this.$v.child.childDate.required && errors.push(`${title}を選択してください`)
      return errors
    },
    setDateToStore() {
      const ci = new ChildInfo()
      ci.selectedAboutChildren = this.child.selectedAboutChildren
      ci.childDate = this.child.childDate
      ci.image = this.child.image
      ci.childName = this.child.childName
      store.dispatch('setChildInformation', { val: ci })
    },
  },
  computed: {
    isEmptyForImage() {
      return this.child.image === null
    },
    childDatePlaceholder() {
      return this.isSelectChild ? 'クリックで生年月日を入力' : 'クリックで出産予定日を入力'
    },
    childDateTitle() {
      return this.isSelectChild ? 'お子様の生年月日' : '出産予定日'
    },
    isSelectChild() {
      return this.child.selectedAboutChildren === Constants.CHILD_INFORMATION_INDEXES.CHILD
    },
    minChildDate() {
      let dt = new Date()
      if (this.isSelectChild) {
        dt.setFullYear(dt.getFullYear() - 15)
        dt.setDate(dt.getDate() + 1)
      }
      return dt
    },
    maxChildDate() {
      let dt = new Date()
      if (!this.isSelectChild) {
        dt.setFullYear(dt.getFullYear() + 1)
      }
      return dt
    },
  },
  watch: {
    // child: {
    //   deep: true,
    //   handler: function() {
    //     console.log('child watch handler')
    //     const ci = new ChildInfo()
    //     ci.selectedAboutChildren = this.child.selectedAboutChildren
    //     ci.childDate = this.child.childDate
    //     ci.image = this.child.image
    //     ci.childName = this.child.childName
    //     store.dispatch('setChildInformation', { val: ci })
    //   },
    // },
  },
}
</script>
