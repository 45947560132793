export default class PaymemtServiceConfig {
  shopCode = '' // 契約コード
  storageCompanyCode = '' // 収納企業コード
  systemPassword = '' //ショップパスワード
  accountTransferUrl = '' // 口座振替URL
  creditRegistUrl = '' // 与信登録
  tokenKey = '' // クレジットカードToken取得用API Key

  static fromApiResponse(data) {
    let psc = new PaymemtServiceConfig()
    psc.shopCode = data.shopCode
    psc.systemPassword = data.systemPassword
    psc.storageCompanyCode = data.storageCompanyCode
    psc.accountTransferUrl = data.accountTransferUrl
    psc.creditRegistUrl = data.creditRegistUrl
    psc.tokenKey = data.tokenKey

    return psc
  }
}
