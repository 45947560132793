<template>
  <div class="pc-wrap memberid-signup-main">
    <v-container class="mt-1">
      <h3 class="memberid-black-600--text title font-weight-bold">{{ this.pageTitle }}</h3>
      <p class="mt-2">組合員の加入には出資金が必要となります。1口1000円からで、脱退時に全額返却となります。</p>
      <v-form v-model="capitalValid" ref="capitalForm">
        <v-btn
          @click="capitalContributionDialog = true"
          text
          color="memberid-primary"
          class="px-0"
          style="text-decoration: underline"
          >出資金とは</v-btn
        >

        <select-box
          :inputTitle="unit.title"
          :requiredFlag="unit.required"
          :placeholder="unit.placeholder"
          :value.sync="quantity"
          :items="unit.list"
          :errorMessages="validQuantity()"
        />
        <radio-button
          :inputTitle="pay.title"
          :requiredFlag="pay.required"
          :placementTextList="pay.placementTextList"
          :value.sync="capital.selectedPay"
        />
        <div v-if="member.selectedDelivery !== 1 && capital.selectedPay === 0">
          <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
            <single-valid-text-field
              :inputTitle="cardHolder.title"
              :requiredFlag="cardHolder.required"
              :placeholder="cardHolder.placeholder"
              :value.sync="cardHoldervalue"
              :errorMessages="validCardHolder()"
              v-on:blur="$v.cardHoldervalue.$touch()"
            />
            <error-messages :isShow="cardErrorMessages.length > 0" :messages="cardErrorMessages"></error-messages>

            <single-valid-text-field
              :inputTitle="cardNumber.title"
              :requiredFlag="cardNumber.required"
              :placeholder="cardNumber.placeholder"
              :value.sync="cardNumbervalue"
              :errorMessages="validCardNumber()"
              v-on:blur="$v.cardNumbervalue.$touch()"
            />
            <error-message :isShow="extraErrorMessage !== ''" :message="extraErrorMessage"></error-message>

            <v-sheet class="rounded-lg memberid-black-100" width="230px">
              <div class="mb-4">
                <v-row>
                  <v-col class="pt-6">
                    <common-input-title :title="expirationDate.title" :requiredFlag="true" />
                  </v-col>
                </v-row>
                <v-row align="center" class="mt-0">
                  <v-col class="pt-2">
                    <v-text-field
                      v-model="expirationDatevalue"
                      color="memberid-primary"
                      background-color="memberid-white"
                      placeholder="MM/YY"
                      :error="$v.expirationDatevalue.$error"
                      outlined
                      hide-details
                      @blur="$v.expirationDatevalue.$touch()"
                      @input="formatExpirationDate()"
                    ></v-text-field>
                  </v-col>
                  <v-col>月/年</v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <error-messages
                      :isShow="$v.expirationDatevalue.$error"
                      :messages="validExpirationDate()"
                    ></error-messages>
                  </v-col>
                </v-row>
              </div>
            </v-sheet>
            <common-input-title title="セキュリティコード" :requiredFlag="true" caption="※半角数字（3桁 or 4桁）" />
            <v-sheet class="rounded-lg memberid-black-100" width="130px">
              <v-text-field
                v-model="securityCodevalue"
                color="memberid-primary"
                background-color="memberid-white"
                placeholder="000"
                :value.sync="securityCodevalue"
                :error="$v.securityCodevalue.$error"
                outlined
                hide-details
                v-on:blur="$v.securityCodevalue.$touch()"
              ></v-text-field>
            </v-sheet>
            <p class="mb-0 mt-3 memberid-black-500--text subtitle-2">
              カード裏面のご署名欄に印字された数字の末尾３桁 <br />
              (AMEXカードの場合はカード表面の4桁)
            </p>
            <error-messages :isShow="$v.securityCodevalue.$error" :messages="validSecurityCode()"></error-messages>
          </v-sheet>
        </div>
        <div v-if="member.selectedDelivery !== 1 && capital.selectedPay === 1" class="caption ma-2">
          ※出資金は毎月10日までの受付で、当月26日（土日祝の場合は翌営業日）のお引き落としになります。<br />
          ※ご登録いただいた口座は、出資金の他に今後コープさっぽろのサービス（※宅配・灯油・電気・プロパン・配食・文化教室受講料）の口座振替でのお支払いに適用されます。ご利用の際は、生活協同組合コープさっぽろより三井住友カード（株）に債権譲渡となります。債権譲渡に伴い、お客さまの個人情報も譲渡に必要な範囲で譲渡人（三井住友カード（株））に提供されます。<br />
          ※2024年4月1日よりSMBCファイナンスサービス（株）は合併し三井住友カード（株）へ社名変更致しました。<br />
        </div>
        <div v-if="member.selectedDelivery === 1" class="caption ma-2">
          ※出資金は毎月10日までの受付で、当月26日（土日祝の場合は翌営業日）のお引き落としになります。<br />
          ※ご登録いただいた口座は、宅配のご利用代金と今後コープさっぽろのサービス（※宅配・灯油・電気・プロパン・配食・文化教室受講料）のお支払いに適用され、毎月26日に引き落としいたします。<br />
          ※ご利用代金につきましては、生活協同組合コープさっぽろより三井住友カード（株）に債権譲渡となります。債権譲渡に伴い、お客さまの個人情報も譲渡に必要な範囲で譲渡人（三井住友カード（株））に提供されます。<br />
          ※口座引落の開始は三井住友カード（株）の口座登録完了後となります。お手続きが完了するまでは振込票にて、郵便局・コンビニよりお支払いください。<br />
          ※2024年4月1日よりSMBCファイナンスサービス（株）は合併し三井住友カード（株）へ社名変更致しました。<br />
        </div>
        <div v-if="member.selectedDelivery === 0 && capital.selectedPay === 0" class="text-body-1 font-weight-bold mt-8 ma-2">
          ※登録完了後は出資金の金額・お支払い方法の変更や、お支払いのキャンセルはできません。ご確認の上、次にお進みください。
        </div>
        <div v-else class="text-body-1 font-weight-bold mt-8 ma-2">
          ※登録完了後は出資金の金額・お支払い方法の変更や、口座引落しのキャンセルはできません。ご確認の上、次にお進みください。
        </div>
        <rounded-button inputTitle="" :nextPage="nextPage" :isdisabled="!capitalValid" />
      </v-form>
    </v-container>
    <v-btn
      v-if="!lastViewIsConfirm"
      text
      @click="backToPreviousPage()"
      class="px-2 mt-6 memberid-primary--text subtitle-1"
    >
      ← 前へ戻る
    </v-btn>

    <v-dialog persistent v-model="capitalContributionDialog" width="450">
      <v-card class="pa-4">
        <h4 class="memberid-black-600--text h6 font-weight-bold">出資金とは</h4>
        <p class="memberid-black-400--text subtitle-2 my-5">
          コープさっぽろの事業や活動をささえるためにお預かりしているお金です。事業や活動を通じて、組合員さまの安全安心な暮らしをお手伝いしています。<br />
          ※「出資金」は「預金」のように自由に引き出すことはできません。
        </p>
        <v-layout justify-center class="mb-3">
          <v-btn
            @click="capitalContributionDialog = false"
            text
            class="memberid-black-400--text font-weight-bold subtitle-1"
            >閉じる</v-btn
          >
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Config from '@/config'
// import Auth0Constants from '@/lib/auth0-constants'
import storage from '@/storage'
// import LoginTokenRequest from '@/api/apimodel/login-token-request'

import { validationMixin } from 'vuelidate'
import store from '@/store'
import RadioButton from '@/components/RadioButtonComponent'
import CommonInputTitle from '@/components/InputTitle'
import SelectBox from '@/components/SelectComponent'
import SingleValidTextField from '@/components/SingleValidTextFieldComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import Vue from 'vue'
import Capital from '@/models/capital'
import Member from '@/models/member'
import { required, numeric, maxLength } from 'vuelidate/lib/validators'
import ErrorMessages from '@/components/ErrorMessageListComponent'
import ErrorMessage from '@/components/ErrorMessageComponent'
// import smbcApiClient from '@/api/smbc-api-client'
import AbstractApiRequest from '@/api/apimodel/abstract-api-request'
import memberidApiClient from '@/api/memberid-api-client'
import FormPost from '@/lib/form-post'
import PaymentServiceConfig from '@/models/payment-service-config'
import PaymentServiceConfigForCc from '@/models/payment-service-config-for-cc'
import StringUtil from '@/lib/string-util'
import PutCreditRequest from '@/api/apimodel/putcredit-request'
import { helpers } from 'vuelidate/lib/validators'

export default {
  name: 'CapitalEntry',
  mixins: [validationMixin],
  components: {
    SelectBox,
    RadioButton,
    CommonInputTitle,
    SingleValidTextField,
    RoundedButton,
    ErrorMessages,
    ErrorMessage,
  },
  validations() {
    const cardHolderRegex = helpers.regex('cardHolderRegex', /^[A-Z]+\s[A-Z]*$/)
    const cardNumberRegex = helpers.regex(
      'cardNumberRegex',
      /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|6(?:011|5[0-9]{2})[0-9]{12}|^(?:2131|1800|35\d{3})\d{11}$)$/
    )
    const expirationDateRegex = helpers.regex('expirationDateRegex', /^(0[1-9]|1[0-2])\/([0-9]{2})$/)
    const securityCodeRegex = helpers.regex('securityCodeRegex', /^[0-9]{3,4}$/)

    let validObj = {
      capital: {
        selectedPay: { required },
        strQuantity: { required },
      },
    }
    if (this.member.selectedDelivery !== 1 && this.capital.selectedPay === 0) {
      validObj.cardHoldervalue = { required, cardHolderRegex, maxLength: maxLength(50) }
      validObj.cardNumbervalue = { required, numeric, cardNumberRegex }
      validObj.expirationDatevalue = { required, expirationDateRegex }
      validObj.securityCodevalue = { required, numeric, securityCodeRegex }
    }
    return validObj
  },
  data() {
    return {
      lastViewIsConfirm: false,
      quantity: '',
      cardErrorMessages: [],
      capitalValid: true,
      capitalContributionDialog: false,
      payvalue: 0,
      selectedUnit: '',
      // selectedPay: 0,
      cardHoldervalue: '',
      cardNumbervalue: '',
      expirationDatevalue: '',
      inputExpirationDateValue: '',
      securityCodevalue: '',
      extraErrorMessage: '',
      pageTitle: '出資金のお支払い',
      pay: { title: 'お支払い方法', required: true, placementTextList: ['クレジットカード', '口座振替'] },
      unit: {
        title: '出資金の金額',
        required: true,
        placeholder: '１口（１０００円）',
        list: [
          { text: '１口（１０００円）', value: '1' },
          { text: '２口（２０００円）', value: '2' },
          { text: '３口（３０００円）', value: '3' },
          { text: '４口（４０００円）', value: '4' },
          { text: '５口（５０００円）', value: '5' },
        ],
      },
      cardHolder: {
        title: 'カード名義人',
        required: true,
        placeholder: 'HANAKO SEIKYOU',
      },
      cardNumber: {
        title: 'カード番号',
        required: true,
        placeholder: '0000 0000 0000 0000',
      },
      expirationDate: {
        title: '有効期限',
        required: true,
        placeholder: 'MM/YY',
      },
      securityCode: {
        title: 'セキュリティコード',
        required: true,
        caption: 'カード裏面のご署名欄に印字された数字の末尾３桁 (AMEXカードの場合はカード表面の4桁)',
      },
      capital: { type: Capital },
      member: { type: Member },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!from || from.name === null) {
      if (!storage.getMemberInfo()) {
        next({ name: 'MemberEntry' })
      }
    }
    next()
  },
  async mounted() {
    if (storage.lastViewIsConfirm()) {
      this.lastViewIsConfirm = true
    }

    this.member = await store.dispatch('getMember')
    this.capital = new Capital()
    const cc = await store.dispatch('getCapital')
    // この画面に入ったときには出資金に関する情報はすべて作成し直す
    // 組合員番号のみ保持する
    if (cc) {
      // this.capital.selectedPay = cc.selectedPay
      // this.capital.quantity = cc.quantity
      // this.capital.strQuantity = cc.strQuantity
      // this.capital.amount = cc.amount
      this.capital.memberCode = cc.memberCode
      this.capital.memberCardId = cc.memberCardId
    }
    // 宅配申し込みしていたら口座だけにする
    if (this.member.selectedDelivery === 1) {
      this.pay.placementTextList = ['口座振替']
      this.pageTitle = '出資金と宅配のお支払い方法'
    }
    // 請求番号、取引情報が残っていたら困るから削除する
    storage.removeShopOrderNumber()
    storage.removeAccessInfo()
  },
  methods: {
    backToPreviousPage() {
      this.$router.go(-1)
    },
    validate() {
      this.$v.$touch()
      return this.$v.$invalid
    },

    async nextPage() {
      Vue.$startLoading()
      // カードのメッセージをリセット
      this.cardErrorMessages = []
      if (this.validate()) {
        Vue.$endLoading()
        return
      }

      // 確認画面から遷移してきて口座を選んで口座があれば確認画面、クレジットカード選んで請求番号があれば確認画面へ
      if (
        (storage.lastViewIsConfirm() &&
          (this.member.selectedDelivery === 1 || this.capital.selectedPay === 1) &&
          storage.isAccountTransferStatus()) ||
        (this.member.selectedDelivery === 0 && this.capital.selectedPay === 0 && storage.getShopOrderNumber())
      ) {
        // 入力値を一応引き継がないと困るので追加
        await this.setCapitalContribution()

        store.dispatch('routerPushWithFromQuery', {
          name: 'MemberConfirm',
        })
        Vue.$endLoading()
        return
      }
      const err = new Error()

      let req = new AbstractApiRequest()
      this.member = await store.dispatch('getMember')
      if (this.isCredit) {
        // ****************
        // クレジットカード
        // ****************
        await memberidApiClient.getPaymentServiceConfigForCc().then(async (response) => {
          Vue.$startLoading()
          const paymentConfigForCc = PaymentServiceConfigForCc.fromApiResponse(response.data)
          const expire = this.inputExpirationDateValue.substring(2, 4) + this.inputExpirationDateValue.substring(0, 2)
          const cardObj = {
              cardno: this.cardNumbervalue,
              expire: expire,
              securityCode: this.securityCodevalue,
              holdername: this.cardHoldervalue,
            }
          // エラーメッセージ初期化
          this.extraErrorMessage = ''

          // マルペイAPI用トークン取得
          window.Multipayment.init(paymentConfigForCc.shopId)
          const token = await new Promise((resolve) => {window.Multipayment.getToken(cardObj,
            response => {
              if (response.resultCode !== "000") {
                this.extraErrorMessage  = `クレジットカード情報の登録に失敗しました。もう一度やり直してください。(${response.resultCode})`
                Vue.$endLoading()
                return
              } else {
                resolve(response.tokenObject.token)
              }
            })
          })

          if (token) {
              if (token.error) {
                // クレジットカードのTokenが取れなかったエラー
                // TODO ちゃんと処理する
                Vue.$showSnackbar({ title: 'システムエラーが発生しました', message: '再度アクセスしてください' })
                throw err
              }

            // 請求番号採番
            let shoporderNumber = ''
            const shoporderResponse = await memberidApiClient.generateShoporderNumber().catch((e) => {
                // console.log('請求番号取得エラー ', e)
              req.defaultErrorProcess(e)
              Vue.$showSnackbar({ title: '請求番号が取得できませんでした', message: '再度アクセスしてください' })
              err.message = '請求番号が取得できませんでした。もう一度やり直してください。'
              throw err
            })

            if (shoporderResponse) {
              shoporderNumber = shoporderResponse.data.shoporderNumber
              this.capital.shoporderNumber = shoporderNumber
                // console.log('shoporder res => ', shoporderResponse)
            } else {
              err.message = '請求番号が取得できませんでした。もう一度やり直してください。'
              Vue.$showSnackbar({ title: '請求番号が取得できませんでした', message: '再度アクセスしてください' })
              throw err
            }

            // 組合員番号取得
            await this.setMemberCode()
            // 与信登録のリクエスト作詞
            const putCreditRequest = this.createPutCreditRequest(token)

            // ========================
            // 与信登録
            // ========================
            await memberidApiClient
              .putCredit(putCreditRequest)
              .then((putCreditResponse) => {
                  // console.log('与信登録 response => ', putCreditResponse)
                putCreditRequest.defaultErrorProcess(putCreditResponse)
                if (putCreditResponse.data.serviceResponseCode !== '000000') {
                  err.message = putCreditResponse.data.serviceResponseMessage
                  throw err
                } else {
                  storage.setAccessInfo(putCreditResponse.data.accessInfo)
                }
                //TODO レスポンスのエラー処理
                // ログ出力もちゃんとする
              })
              .catch((e) => {
                  // console.log('与信登録 error => ', e)
                putCreditRequest.defaultErrorProcess(e)
                  // ここはLambda経由でSMBCからくるエラーを表示する場所だと思うからSnackBarいらないのでは？
                  // Vue.$showSnackbar({ title: 'システムエラーが発生しました', message: '再度アクセスしてください' })
                throw e
              })

            // storeに設定
            await this.setCapitalContribution()
            storage.setShopOrderNumber(this.capital.shoporderNumber)

            // 確認画面に遷移
            // 戻るボタンで戻ってほしくないから書き換える
            this.$router.replace({
              name: 'MemberConfirm',
            })
          }
          Vue.$endLoading()
        })
        .catch((e) => {
          req.defaultErrorProcess(e)
          this.cardErrorMessages.push(e.message)
        })
        .finally(async () => {
          await this.setCapitalContribution()
          Vue.$endLoading()
        })
      } else {
        // ****************
        // 口座振替
        // ****************

        await memberidApiClient.getPaymentServiceConfig().then(async (response) => {
          Vue.$startLoading()
          const paymentConfig = PaymentServiceConfig.fromApiResponse(response.data)
          // 組合員番号取得
          await this.setMemberCode()
          // storeに設定
          await this.setCapitalContribution()
          Vue.$endLoading()
          // 口座登録
          FormPost.accountTransferPost(this.member, paymentConfig, this.capital)
        })
        .catch((e) => {
          req.defaultErrorProcess(e)
          this.cardErrorMessages.push(e.message)
        })
        .finally(async () => {
          await this.setCapitalContribution()
          Vue.$endLoading()
        })
      }
      // console.log('*** capital next end ***')
    },
    async setMemberCode() {
      // すでに組合員番号をもらっていたら何もしない
      if (this.capital.memberCardId.length > 0 && this.capital.memberCode > 0) {
        // console.log('-------- member code exists ----------')
        return
      }
      let req = new AbstractApiRequest()
      let err = new Error()
      await memberidApiClient
        .generateMembercode()
        .then((res) => {
          const memberCode = res.data.memberCode
          this.capital.memberCode = memberCode
          const member12 = memberCode + '1000'
          const checkdigit = StringUtil.calcCheckDigit2(member12)
          this.capital.memberCardId = member12 + checkdigit
          // console.log(' memberCode -> ', memberCode)
        })
        .catch((e) => {
          req.defaultErrorProcess(e)
          // console.log('組合員番号採番エラー', e)
          err.message = '組合員番号の取得に失敗しました'
          Vue.$showSnackbar({ title: '組合員番号の取得に失敗しました', message: 'もう一度実行してください' })
          Vue.$endLoading()
          throw err
        })
    },

    validQuantity() {
      const errors = []
      if (!this.$v.capital.strQuantity.$dirty) return errors
      !this.$v.capital.strQuantity.required && errors.push(`${this.unit.title}をご入力ください`)
      return errors
    },

    validCardHolder() {
      const errors = []
      if (!this.$v.cardHoldervalue.$dirty) return errors
      !this.$v.cardHoldervalue.required && errors.push(`${this.cardHolder.title}をご入力ください`)
      //      !this.$v.cardHoldervalue.alpha && errors.push(`${this.cardHolder.title}をアルファベット文字でご入力ください`)
      !this.$v.cardHoldervalue.cardHolderRegex &&
        errors.push(`${this.cardHolder.title}を大文字半角アルファベット文字・姓名の間にスペースでご入力ください`)
      !this.$v.cardHoldervalue.maxLength && errors.push(`${this.cardHolder.title}は50文字以下でご入力ください`)
      return errors
    },

    validCardNumber() {
      const errors = []
      if (!this.$v.cardNumbervalue.$dirty) return errors
      !this.$v.cardNumbervalue.required && errors.push(`${this.cardNumber.title}をご入力ください`)
      !this.$v.cardNumbervalue.numeric && errors.push(`${this.cardNumber.title}を半角数字でご入力ください`)
      !this.$v.cardNumbervalue.cardNumberRegex && errors.push(`${this.cardNumber.title}が不正です`)
      return errors
    },

    validExpirationDate() {
      const errors = []
      if (!this.$v.expirationDatevalue.$dirty) return errors
      !this.$v.expirationDatevalue.required && errors.push(`${this.expirationDate.title}をご入力ください`)
      !this.$v.expirationDatevalue.expirationDateRegex &&
        errors.push(`${this.expirationDate.title}をMM/YYでご入力ください`)
      return errors
    },

    validSecurityCode() {
      const errors = []
      if (!this.$v.securityCodevalue.$dirty) return errors
      !this.$v.securityCodevalue.required && errors.push(`${this.securityCode.title}をご入力ください`)
      //      !this.$v.securityCodevalue.numeric && errors.push(`${this.securityCode.title}を半角数字でご入力ください`)
      !this.$v.securityCodevalue.securityCodeRegex &&
        errors.push(`${this.securityCode.title}は3～4桁の半角数字でご入力ください`)
      return errors
    },

    formatExpirationDate() {
      this.inputExpirationDateValue = this.expirationDatevalue.replace(/\//g, '')
      let str = this.inputExpirationDateValue
      // console.log('expireDate ', str)
      let month = str.slice(0, 2)
      let year = str.slice(2, 4)
      // console.log(`month -> ${month} year -> ${year}`)
      // console.log('str.length ', str.length)
      // console.log('year.length ', year.length)
      if (str.length === 2 || year.length > 0) {
        this.expirationDatevalue = month + '/' + year
      } else {
        this.expirationDatevalue = month + year
      }
      // 最後がスラッシュになってしまった場合は削除する
      if (this.expirationDatevalue.length === 3 && this.expirationDatevalue.lastIndexOf('/') === 2) {
        this.expirationDatevalue = this.inputExpirationDateValue
      }
    },

    createPutCreditRequest(token) {
      const putCreditRequest = new PutCreditRequest()
      putCreditRequest.orderId = this.capital.shoporderNumber
      putCreditRequest.amount = this.capital.getAmount()
      putCreditRequest.memberCardId = this.capital.memberCardId
      putCreditRequest.token = token
      return putCreditRequest
    },

    async setCapitalContribution() {
      // console.log('set capital store')
      const capi = new Capital()
      capi.selectedPay = this.capital.selectedPay
      capi.quantity = this.capital.quantity
      capi.strQuantity = this.capital.strQuantity
      capi.amount = this.capital.amount
      capi.memberCode = this.capital.memberCode
      capi.memberCardId = this.capital.memberCardId
      await store.dispatch('setCapital', { val: capi })
    },
  },
  computed: {
    isCredit() {
      // 宅配じゃない場合にのみクレジットカードが0になる
      return this.member.selectedDelivery !== 1 && this.capital.selectedPay === 0
    },
  },
  watch: {
    quantity: function (val) {
      this.capital.setQuantity(val)
    },
  },
}
</script>

<style>
.memberid-header-bottom {
  border-bottom: 1px solid #cfcfcf;
}
</style>
