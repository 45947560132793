export default class MutationTypes {
  // 使用中
  static SET_MEMBER = 'SET_MEMBER'
  static SET_DELIVERY_INFORMATION = 'SET_DELIVERY_INFORMATION'
  static SET_CHILD_INFORMATION = 'SET_CHILD_INFORMATION'
  static SET_CAPITAL = 'SET_CAPITAL'

  static SET_ERROR_MESSAGES = 'SET_ERROR_MESSAGES'
  static SET_FROM = 'SET_FROM'

  // 未使用
  static SET_PASSWORDLESS_START_REQUEST = 'SET_PASSWORDLESS_START_REQUEST'

  static SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
  static SET_ID_TOKEN = 'SET_ID_TOKEN'
  static SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'

  static SET_TOKEN_K = 'SET_TOKEN_K'
  static RESTORE_TOKEN_K = 'RESTORE_TOKEN_K'
  static SET_MOBILE_APP_TRACKER = 'SET_MOBILE_APP_TRACKER'

  static SET_MEMBER_ADD_INFO = 'SET_MEMBER_ADD_INFO'
  static SET_MEMBER_TODOK_USER = 'SET_MEMBER_TODOK_USER'
  static INIT_APP_CATEGORIES = 'INIT_APP_CATEGORIES'
  static SET_APP_CATEGORIES = 'SET_APP_CATEGORIES'

  static SET_CART = 'SET_CART'
  static SET_ORDER = 'SET_ORDER'
  static SET_PLANNING_WEEKS = 'SET_PLANNING_WEEKS'
  static SET_CURRENT_PLANNING_WEEK_INDEX = 'SET_CURRENT_PLANNING_WEEK_INDEX'
  static SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
  static SET_FAVORITES = 'SET_FAVORITES'
  static SET_NOTICES = 'SET_NOTICES'
  static SET_PARTICULAR_ORDER = 'SET_PARTICULAR_ORDER'
  static SET_POINT_BALANCE = 'SET_POINT_BALANCE'
  static SET_COME_EVERY_INFORMATION = 'SET_COME_EVERY_INFORMATION'

  static SET_ORDER_HISTORY = 'SET_ORDER_HISTORY'
  static SET_CURRENT_PLANNING_WEEK_NUMBER_FOR_ORDER_HISTORY = 'SET_CURRENT_PLANNING_WEEK_NUMBER_FOR_ORDER_HISTORY'

  static SET_REPEAT_PURCHASE = 'SET_REPEAT_PURCHASE'
  static SET_IS_SHOW_REPEAT_PURCHASE = 'SET_IS_SHOW_REPEAT_PURCHASE'
  static SET_CURRENT_APP_CATEGORY_CODE = 'SET_CURRENT_APP_CATEGORY_CODE'

  static SET_CURRENT_ORDER_SOURCE = 'SET_CURRENT_ORDER_SOURCE'

  static INIT_PICKUP_ITEMS = 'INIT_PICKUP_ITEMS'
  static APPEND_PICKUP_ITEMS = 'APPEND_PICKUP_ITEMS'

  static INIT_POPULAR_PRODUCTS = 'INIT_POPULAR_PRODUCTS'
  static APPEND_POPULAR_PRODUCTS = 'APPEND_POPULAR_PRODUCTS'
  static SET_POPULAR_PRODUCTS = 'SET_POPULAR_PRODUCTS'

  static SET_FAQ_ITEMS = 'SET_FAQ_ITEMS'
  static SET_QA_ITEMS = 'SET_QA_ITEMS'

  static SET_CATALOGUES = 'SET_CATALOGUES'

  static SET_CURRENT_POINT = 'SET_CURRENT_POINT'
  static RESERVE_SET_POINT = 'RESERVE_SET_POINT'
  static SET_POINT_HISTORIES = 'SET_POINT_HISTORIES'
  static SET_USE_TODOK_POINT = 'SET_USE_TODOK_POINT'
  static SET_USE_CAPITAL_POINT = 'SET_USE_CAPITAL_POINT'
  static SET_TODAY_HISTORIES = 'SET_TODAY_HISTORIES'
}
