<template>
  <div>
    <v-row class="mx-4 mt-2">
      <p v-if="isShow" class="body-2 memberid-red--text font-weight-bold">・{{ message }}</p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessageComponent',
  props: {
    isShow: { type: Boolean },
    message: { type: String },
  },
}
</script>
