import Vue from 'vue'
import createAuth0Client from '@auth0/auth0-spa-js'

/**
 * Auth0 SDKのWrapper
 *
 * Auth0 コンソール / Applications / 組合員IDシステム / Quick Start / Vue.js からの引用
 *
 */

/** 自身の唯一のインスタンス */
let instance

/** 自身のインスタンスを返す */
export const getInstance = () => instance

/**
 * インスタンスが作成済みであればそのまま返す。
 */
export const useAuth0 = ({ onRedirectCallback, redirectUri, ...options }) => {
  // パラメーターのチェック
  if (!onRedirectCallback) {
    throw new Error('onRedirectCallbackが指定されていません。')
  }
  // パラメーターのチェック
  if (!redirectUri) {
    throw new Error('redirectUriが指定されていません。')
  }

  // インスタンスが作成済みなら返す
  if (instance) {
    return instance
  }

  // Vue オブジェクトとしてInstanceを生成する
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
      }
    },
    methods: {
      // 組合員IDシステムではloginWithPopup()は使用しない
      // /** Authenticates the user using a popup window */
      // async loginWithPopup(options, config) {
      //   this.popupOpen = true;

      //   try {
      //     await this.auth0Client.loginWithPopup(options, config);
      //     this.user = await this.auth0Client.getUser();
      //     this.isAuthenticated = await this.auth0Client.isAuthenticated();
      //     this.error = null;
      //   } catch (e) {
      //     this.error = e;
      //     // eslint-disable-next-line
      //     console.error(e);
      //   } finally {
      //     this.popupOpen = false;
      //   }

      //   this.user = await this.auth0Client.getUser();
      //   this.isAuthenticated = true;
      // },
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback() {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          this.error = null
        } catch (e) {
          this.error = e
        } finally {
          this.loading = false
        }
      },
      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o)
      },

      /**
       * Returns all the claims present in the ID token
       * ID Tokenを返す
       * */
      getIdTokenClaims(o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      /**
       * AccessTokenを返す。Tokenが無効な場合は新しいAccessTokenを取得して返す。
       *
       * Returns the access token. If the token is invalid or missing, a new one is retrieved
       * */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o)
      },
      // 組合員IDシステムでは使用しない
      // /** Gets the access token using a popup window */
      // getTokenWithPopup(o) {
      //   return this.auth0Client.getTokenWithPopup(o);
      // },

      // 組合員IDシステムでは使用しない
      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o)
      },

      getRefreshToken() {
        const key =
          '@@auth0spajs@@::' + options.clientId + '::' + options.audience + '::' + 'openid profile email offline_access'
        const auth0Storage = JSON.parse(localStorage.getItem(key))
        return auth0Storage.body.refresh_token
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        redirect_uri: redirectUri,
        cacheLocation: 'localstorage',
        scope: 'openid email offline_access',
      })

      try {
        // If the user is returning to the app after authentication..
        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback()

          this.error = null

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState)
        }
      } catch (e) {
        this.error = e
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        this.loading = false
      }
    },
  })

  return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  },
}
