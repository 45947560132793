<template>
  <v-container>
    <v-card
      :href="link"
      target="_blank"
      rel="noopener noreferrer"
      class="rounded-lg"
      width="100%"
      :height="cardHeight"
      :class="themeColorClass"
    >
      <v-row no-gutters align="center" class="px-2" style="height:100%;">
        <v-col>
          <v-card-title class="pa-1 font-weight-bold" :class="titleClass">
            {{ title }}
          </v-card-title>
          <v-card-text class="pa-1 font-weight-500" :class="descriptionClass">
            {{ description }}
          </v-card-text>
        </v-col>
        <v-col justify="end" cols="1">
          <v-card-text class="pa-1 text-end">
            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              :class="themeColorClass"
            >
              <path
                d="M2.00001 14.9999C1.76635 15.0004 1.53992 14.919 1.36 14.7699C1.25875 14.686 1.17504 14.5829 1.11369 14.4665C1.05234 14.3502 1.01454 14.2229 1.00247 14.0919C0.990393 13.9609 1.00427 13.8289 1.04332 13.7033C1.08236 13.5777 1.1458 13.461 1.23 13.3599L5.71001 7.99994L1.39001 2.62994C1.30694 2.52765 1.24491 2.40996 1.20748 2.28362C1.17004 2.15728 1.15795 2.02479 1.17189 1.89376C1.18583 1.76273 1.22553 1.63575 1.2887 1.52011C1.35187 1.40447 1.43728 1.30246 1.54001 1.21994C1.64347 1.1289 1.76464 1.06024 1.8959 1.01825C2.02716 0.976258 2.16569 0.961855 2.30278 0.97594C2.43987 0.990024 2.57257 1.03229 2.69256 1.1001C2.81254 1.1679 2.91721 1.25977 3.00001 1.36994L7.83001 7.36994C7.97709 7.54887 8.05749 7.77332 8.05749 8.00494C8.05749 8.23657 7.97709 8.46101 7.83001 8.63994L2.83001 14.6399C2.72969 14.761 2.60225 14.8566 2.45804 14.9192C2.31383 14.9817 2.15691 15.0094 2.00001 14.9999Z"
                fill="currentColor"
              />
            </svg>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Constants from '@/lib/constants'
export default {
  props: {
    theme: {
      validator: value => Object.values(Constants.LINK_BANNER_THEMES).indexOf(value) !== -1,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    link: {
      type: String,
    },
  },
  data() {
    return { themeColorClass: '', cardHeight: 0, titleClass: '', descriptionClass: '' }
  },
  mounted() {
    switch (this.theme) {
      case Constants.LINK_BANNER_THEMES.PRIMARY:
        this.themeColorClass = 'memberid-white--text memberid-primary'
        this.titleClass = 'primary-title'
        this.descriptionClass = 'primary-description'
        this.cardHeight = 84
        break
      case Constants.LINK_BANNER_THEMES.SECONDARY:
        this.themeColorClass = 'memberid-secondary--text memberid-tertiary'
        this.titleClass = 'secondary-title'
        this.descriptionClass = 'secondary-description'
        this.cardHeight = 100
        break
      default:
        break
    }
  },
}
</script>

<style scoped>
.primary-title {
  font-size: 18px;
  line-height: 27px;
}
.secondary-title {
  font-size: 20px;
  line-height: 42px;
}
.primary-description {
  font-size: 14px;
  line-height: 21px;
}
.secondary-description {
  font-size: 16px;
  line-height: 24px;
}
</style>
