<template>
  <div>
    <h4 class="memberid-black-600--text subtitle-1 font-weight-bold pt-0 mb-2">
      {{ title }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
  },
}
</script>
