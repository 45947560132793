// import apiClientBase from '@/api/api-client-base'
// import GetMemberRequest from '@/api/apimodel/get-member-request'
// import GetMemberAddInfoRequest from '@/api/apimodel/get-member-add-info-request'
// import GetPlanningWeeksRequest from '@/api/apimodel/get-planning-weeks-request'
// import GetPointBalanceRequest from '@/api/apimodel/get-point-balance-request'
// import SendMailRequest from '@/api/apimodel/send-mail-request'
// import memberidApiClient from '@/api/memberid-api-client'
import Capital from '@/models/capital'
// // import Config from '@/config'
import storage from '@/storage'
import store from '@/store'
// import router from '@/router'
// import Vue from 'vue'
import MutationTypes from '@/store/mutation-types'

const state = {
  capital: new Capital(),
  hasCapitalData: false,
}

const getters = {
  capital: state => state.capital,
  hasCapitalData: state => state.hasCapitalData,
}

const mutations = {
  [MutationTypes.SET_CAPITAL](state, { capital }) {
    state.capital = capital
    state.hasCapitalData = true
  },
}

const actions = {
  setCapital({ commit }, { val }) {
    commit(MutationTypes.SET_CAPITAL, { capital: val })
    storage.setCapitalInfo(val)
  },

  // 出資金情報
  getCapital({ commit }) {
    const capitalInfo = store.getters.capital
    if (!store.getters.hasCapitalData) {
      const stg = storage.getCapitalInfo()
      if (stg) {
        const ct = new Capital()
        ct.selectedPay = stg.selectedPay
        ct.quantity = stg.quantity
        ct.strQuantity = stg.strQuantity
        ct.amount = stg.amount
        ct.memberCode = stg.memberCode
        ct.memberCardId = stg.memberCardId
        // //TODO いるんだっけ？請求番号保持したくないから削除？
        // // ct.shoporderNumber = stg.shoporderNumber
        commit(MutationTypes.SET_CAPITAL, { capital: ct })
        return ct
      }
    }
    return capitalInfo
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
