// import Config from '@/config'
import Vue from 'vue'
import router from '@/router'

export default class AbstractApiRequest {
  displayError = true

  onSuccess = () => {}
  // onFailure = error => {}

  /**
   * 503ならSorryページにリダイレクト
   * それ以外なら、showErrorSnackbarを起動
   */
  defaultErrorProcess(error) {
    if (error.response) {
      if (503 === error.response.status) {
        // ぐるぐるしていた場合があるから止める
        Vue.$endLoading()
        router.push('maintenance')
        return
      }
    }
    // if (this.displayError) {
    //   Vue.$showErrorSnackbar(error)
    // }
  }

  toQuery() {
    let queries = []
    for (const key in this) {
      if (this.mustAvoid(key)) {
        // do nothing
      } else {
        const query = key + '=' + this[key]
        queries.push(query)
      }
    }
    return queries.join('&')
  }

  toHash() {
    let hash = {}
    for (const key in this) {
      if (this.mustAvoid(key)) {
        // do nothing
      } else {
        hash[key] = this[key]
      }
    }
    return hash
  }

  toPostQuery() {
    let params = new URLSearchParams()
    for (const key in this) {
      if (this.mustAvoid(key)) {
        // do nothing
      } else {
        params.append(key, this[key])
      }
    }
    return params
  }

  mustAvoid(key) {
    for (let i = 0; i < this.avoidKeys().length; i++) {
      if (key.toString() === this.avoidKeys()[i]) {
        return true
      }
    }
    return false
  }

  avoidKeys() {
    return ['onSuccess', 'onFailure', 'displayError']
  }
}
