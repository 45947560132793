import DeliveryInformation from '@/models/delivery-information'

export default {
  data() {
    return {
      deliveryInformation: null,
      residenceTextList: ['戸建て', '集合住宅でオートロック無し', '集合住宅でオートロック有り'],
      placementTextList: ['玄関フード', '物置', '車庫', '玄関ドア横', 'その他'],
      confirmationOfDeliveryTextList: ['チャイム・インターホンを鳴らす', 'チャイム・インターホンを鳴らさない'],
      deliveryOfPaperCatalogTextList:['配付を希望しない', '配付を希望する'],
      todokTrialCampaignTextList:['希望する', '希望しない'],
      aboutChildrenTextList: ['現在、妊娠中', '0〜15歳未満'],
    }
  },
  methods: {
    buildDeliveryInformation() {
      const deliveryInformation = this.stateDeliveryInformation
      let dai = new DeliveryInformation()
      dai.todokApplying = deliveryInformation.todokApplying
      dai.selectedAddress = deliveryInformation.selectedAddress
      dai.postNumber = deliveryInformation.postNumber
      dai.addressList = deliveryInformation.addressList
      dai.address1 = deliveryInformation.address1
      dai.address2 = deliveryInformation.address2
      dai.address3 = deliveryInformation.address3
      dai.selectedTel = deliveryInformation.selectedTel
      dai.phoneNumber = deliveryInformation.phoneNumber
      dai.selectedResidence = deliveryInformation.selectedResidence
      dai.selectedPlacement = deliveryInformation.selectedPlacement
      dai.placementOtherInput = deliveryInformation.placementOtherInput
      dai.selectedConfirmationOfDelivery = deliveryInformation.selectedConfirmationOfDelivery
      dai.selectedAboutChildren = deliveryInformation.selectedAboutChildren
      dai.childDate = deliveryInformation.childDate
      dai.image = deliveryInformation.image
      dai.childName = deliveryInformation.childName
      dai.selectedIntroducer = deliveryInformation.selectedIntroducer
      dai.introducerName = deliveryInformation.introducerName
      dai.introducerUnionNumber = deliveryInformation.introducerUnionNumber
      dai.introducerPhoneNumber = deliveryInformation.introducerPhoneNumber
      this.deliveryInformation = dai
    },
  },
  computed: {
    stateDeliveryInformation() {
      return this.$store.getters.deliveryInformation
    },
  },
}
