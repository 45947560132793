import apiClientBase from '@/api/api-client-base'
import Config from '@/config'

class MemberidDataClient {
  // getPopularProductData() {
  //   const endpoint = this.getJsonBase() + '/popular-items.json'
  //   return apiClientBase.getHttpClient().get(endpoint)
  // }

  // getFaqItemData() {
  //   const endpoint = this.getJsonBase() + '/faq-items.json'
  //   return apiClientBase.getHttpClient().get(endpoint)
  // }

  // getNoticesData() {
  //   const endpoint = this.getJsonBase() + '/notices.json'
  //   return apiClientBase.getHttpClient().get(endpoint)
  // }

  getErrorMessageData() {
    const endpoint = this.getJsonBase() + '/error-messages.json'
    return apiClientBase.getHttpClient().get(endpoint)
  }

  // getCataloguesData() {
  //   const endpoint = this.getJsonBase() + '/catalogues.json'
  //   return apiClientBase.getHttpClient().get(endpoint)
  // }

  getJsonBase() {
    return Config.currentEnv.memberidDataServerEndpoint
  }
}

const memberidDataClient = new MemberidDataClient()
export default memberidDataClient
