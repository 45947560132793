import StringUtil from '@/lib/string-util'

export default class FormPost {
  static createHideInput(name, value, id = null) {
    let inputElemtent = document.createElement('input')
    inputElemtent.name = name
    inputElemtent.value = value
    inputElemtent.type = 'hidden'
    if (id) {
      inputElemtent.id = id
    }
    return inputElemtent
  }

  static createForm() {
    let formElement = document.createElement('form')
    formElement.name = 'postForm'
    formElement.id = 'post-form'
    formElement.method = 'post'

    return formElement
  }

  static removeForm() {
    const formElem = document.getElementById('post-form')
    if (formElem) {
      formElem.remove()
    }
  }

  // 口座振替
  static accountTransferPost(member, payment, capital) {
    // 前に使ったFormがあれば削除
    FormPost.removeForm()
    let postFormElement = FormPost.createForm()
    postFormElement.action = payment.accountTransferUrl
    postFormElement.enctype = 'application/x-www-form-urlencoded'
    postFormElement.acceptCharset = 'Shift_JIS'
    // バージョン（必須）
    let inputElementVersion = FormPost.createHideInput('version', '130')
    postFormElement.appendChild(inputElementVersion)
    // 決済手段区分
    let inputElementBillMethod = FormPost.createHideInput('bill_method', '01')
    postFormElement.appendChild(inputElementBillMethod)
    // 決済種類コード
    let inputElementKessaiId = FormPost.createHideInput('kessai_id', '0101')
    postFormElement.appendChild(inputElementKessaiId)
    // 契約コード（必須）
    let inputElementShopCd = FormPost.createHideInput('shop_cd', payment.shopCode)
    postFormElement.appendChild(inputElementShopCd)
    // 収納企業コード（必須）
    let inputElementSyunoCd = FormPost.createHideInput('syuno_co_cd', payment.storageCompanyCode)
    postFormElement.appendChild(inputElementSyunoCd)
    // ショップパスワード（必須）
    // クレジットカードの都度決済でパスワードを使用するのでこちらもパスワードにした
    let inputElementShopPwd = FormPost.createHideInput('shop_pwd', payment.systemPassword)
    postFormElement.appendChild(inputElementShopPwd)
    // 上書可否区分（必須） 0→上書き不可 1→上書き可
    let inputElementKoushinKbn = FormPost.createHideInput('koushin_kbn', '1')
    postFormElement.appendChild(inputElementKoushinKbn)
    // 顧客番号（必須）14桁
    let inputElementBillNo = FormPost.createHideInput('bill_no', capital.getStrMemberCardId())
    postFormElement.appendChild(inputElementBillNo)
    // 顧客名（必須）
    let inputElementBillName = FormPost.createHideInput('bill_name', member.fullName)
    postFormElement.appendChild(inputElementBillName)
    // 顧客カナ名（必須かも？）
    //TODO
    let inputElementBillKana = FormPost.createHideInput('bill_kana', StringUtil.zenkana2Hankana(member.fullNameKana))
    postFormElement.appendChild(inputElementBillKana)
    // 住所5に出資金を設定
    let inputElementBillAdr5 = FormPost.createHideInput('bill_adr_5', capital.getAmount())
    postFormElement.appendChild(inputElementBillAdr5)
    // 遷移先URL（必須じゃないけど、ないと戻ってこられないため必要）
    let inputElementShopLink = FormPost.createHideInput('shop_link', window.location.origin + '/accountComplete')
    postFormElement.appendChild(inputElementShopLink)
    // 結果通知URL
    // 登録された時に特定のフォーマットでデータを送信してくれるサービスで使うもの
    // 今回は契約していないので使用不可
    // let inputElementShopResLink = FormPost.createHideInput('shop_res_link', window.location.origin + '/')
    // postFormElement.appendChild(inputElementShopResLink)
    // エラー時遷移先URL（SMBC画面でエラーがあれば戻る画面）
    let inputElementShopErrorLink = FormPost.createHideInput(
      'shop_error_link',
      window.location.origin + '/accountError'
    )
    postFormElement.appendChild(inputElementShopErrorLink)
    // リダイレクト区分(1: リダイレクトする 0: リダイレクトしない)
    let inputElementRedirectKbn = FormPost.createHideInput('redirect_kbn', '1')
    postFormElement.appendChild(inputElementRedirectKbn)
    // リダイレクト秒数
    let inputElementRedirectSec = FormPost.createHideInput('redirect_sec', '10')
    postFormElement.appendChild(inputElementRedirectSec)
    // ショップ連絡先電話番号表示区分（必須）0→表示しない 1→表示する
    let inputElementShopPhoneKbn = FormPost.createHideInput('shop_phon_hyoji_kbn', '0')
    postFormElement.appendChild(inputElementShopPhoneKbn)
    // ショップ連絡先メールアドレス表示区分（必須）0→表示しない 1→表示する
    let inputElementShopMailKbn = FormPost.createHideInput('shop_mail_hyoji_kbn', '0')
    postFormElement.appendChild(inputElementShopMailKbn)
    // 受付ヘッダ文言1（必須ではない）
    let inputElementShopMsg1 = FormPost.createHideInput(
      'shop_msg_1',
      'ご利用代金のお引き落としは毎月２６日です。出資金は１０日までの受付で当月引き落としとなります。'
    )
    postFormElement.appendChild(inputElementShopMsg1)
    // 受付ヘッダ文言2（必須ではない）
    let inputElementShopMsg2 = FormPost.createHideInput(
      'shop_msg_2',
      '口座登録のお手続き完了後にお支払い方法の変更はできませんのでご注意ください。'
    )
    postFormElement.appendChild(inputElementShopMsg2)
    document.body.appendChild(postFormElement)
    document.getElementById('post-form').submit()
  }
}
