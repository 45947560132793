<template>
  <div class="mb-6">
    <v-row v-if="inputTitle !== ''">
      <v-col class="pt-6">
        <common-input-title :title="inputTitle" :requiredFlag="requiredFlag" :caption="caption" />
      </v-col>
    </v-row>
    <v-row align="center" class="mt-0 mb-1">
      <v-col cols="12" class="pt-2">
        <v-text-field
          v-model="inputValue"
          color="memberid-primary"
          background-color="memberid-white"
          :placeholder="placeholder"
          :error="hasError"
          :disabled="disabledFlag"
          outlined
          hide-details
          @blur="$emit('blur', $event)"
          @change="$emit('change', $event)"
        ></v-text-field>
        <p class="mb-0 memberid-black-500--text subtitle-2">
          {{ underText }}
        </p>
      </v-col>
    </v-row>
    <error-messages :isShow="hasError" :messages="errorMessages"></error-messages>
  </div>
</template>

<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessages from '@/components/ErrorMessageListComponent'
export default {
  name: 'SingleValidTextField',
  components: { CommonInputTitle, ErrorMessages },
  props: {
    placeholder: { type: String },
    value: { type: String },
    disabledFlag: { type: Boolean },
    inputTitle: { type: String },
    caption: { type: String },
    requiredFlag: { type: Boolean },
    errorMessages: { type: Array },
    underText: { type: String },
  },
  computed: {
    hasError: function() {
      return typeof this.errorMessages !== 'undefined' && this.errorMessages.length > 0
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>
