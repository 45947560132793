<template>
  <div class="mt-8">
    <v-btn
      id="custom-disabled-next"
      height="52"
      width="100%"
      color="memberid-primary"
      class="rounded-pill memberid-white--text font-weight-bold subtitle-1"
      @click="goToNextPage()"
      :disabled="isdisabled"
    >
      {{ buttonText }}
    </v-btn>
  </div>
</template>
<script>
export default {
  name: 'RoundedButtonComponent',
  props: {
    nextPage: { type: Function },
    isdisabled: {
      type: Boolean,
    },
    buttonText: { type: String, default: '次へ' },
  },

  methods: {
    async goToNextPage() {
      await this.nextPage()
    },
  },
}
</script>
