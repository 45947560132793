import AbstractApiRequest from '@/api/apimodel/abstract-api-request'

export default class DuplicateRequest extends AbstractApiRequest {
  lastName = ''
  firstName = ''
  birthday = ''
  // 1: 男性 2: 女性
  sexuality = ''
  //
  /** 画面側からは固定
   * 20：強制脱退
   * 93：脱退処理中(即金)
   * 94：脱退済
   * 90：脱退受付済
   * 91：脱退保留中
   * 92：脱退処理中
   */
  type = '0'
}
