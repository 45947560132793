import { helpers } from 'vuelidate/lib/validators'
import Encoding from 'encoding-japanese'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import StringUtil from '@/lib/string-util'

export const checkZenSpace = val => (val || '').indexOf('　') < 0
export const length50 = val => (val || '').length <= 50
export const length60 = val => (val || '').length <= 60
export const zenkaku = helpers.regex('zenkaku', /^[^ -~｡-ﾟ]*$/)
export const zenkakuWithSpace = helpers.regex('zenkakuWithSpace', /^[^-~｡-ﾟ]*$/)
export const hanKana = helpers.regex('hanKana', /^[ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､ ]*$/)
export const zenKana = helpers.regex('zenKana', /^[ァ-ヶー]+$/)
export const checkShiftJis = str => {
  const sjisString = Encoding.convert(str, {
    to: 'SJIS',
    from: 'UNICODE',
    type:'string'
  });
  const utf8String = Encoding.convert(sjisString, {
    to: 'UNICODE',
    from: 'SJIS',
    type:'string'
  });


  return utf8String === str
}
export const isShowPhoneNumberError = val => {
  //＝＝＝＝＝＝
  // 関数定義
  //＝＝＝＝＝＝
  // 電話番号の有効性チェック関数
  const phoneNumberNoneProblem = function(val) {
    if (val) {
      // +81は日本の電話番号を示す
      const parsedPhoneNumber = parsePhoneNumberFromString('+81' + val, 'JP')
      return parsedPhoneNumber ? parsedPhoneNumber.isValid() : true
    } else {
      return true
    }
  }
  // ハイフン有無を確認する関数
  const isHaihun = RegExp('-')

  //＝＝＝＝＝＝
  // 関数呼び出し
  //＝＝＝＝＝＝

  // 番号の有効性チェック
  if (!phoneNumberNoneProblem(val)) {
    return false
  }

  // 有効な番号の場合整形
  const formatedPhoneNumber = StringUtil.formatPhoneNumber(val)

  // 整形後にハイフンが入ってない番号 ＝ 存在し得ない番号
  if (!isHaihun.test(formatedPhoneNumber)) {
    return false
  }
  return true
}
