<template>
  <div class="mt-2 pt-2">
    <v-row>
      <v-col col="2" md="2"> {{ title }} : </v-col>
      <v-col cols="8" v-if="content === undefined">
        <div v-for="(item, i) in contents" :key="i">
          {{ item }}
        </div>
      </v-col>
      <v-col cols="8" v-if="content !== undefined">
        {{ content }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    contents: {
      type: Array,
    },
    content: {
      type: String,
    },
  },
}
</script>
