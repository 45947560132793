import axios from 'axios'
import { getInstance as getAuth0Instance } from '@/auth'

class ApiClientBase {
  async getAuthenticatedClient() {
    const authHeader = await this.getAuthenticationHeader()
    return this.getHttpClient(authHeader)
  }

  getHttpClient(additionalHeaders = null) {
    let headers = this.getDefaultHeader()
    if (additionalHeaders) {
      headers = Object.assign(headers, additionalHeaders)
    }
    return axios.create({
      headers: headers,
    })
  }

  getDefaultHeader() {
    return {}
  }

  async getAuthenticationHeader() {
    const auth0 = getAuth0Instance()
    let accessToken = ''
    // トークン取得で未ログインエラーの場合はログインページに遷移する
    try {
      accessToken = await auth0.getTokenSilently()
    } catch (e) {
      if (e.error === 'login_required') {
        //TODO 行き先指定しなきゃならないかも
        // { appState: { targetUrl: to.fullPath }
        await auth0.loginWithRedirect()
        return
      } else {
        throw e
      }
    }
    let token = 'Bearer ' + accessToken
    return { Authorization: token }
  }
}

const apiClientBase = new ApiClientBase()
export default apiClientBase
