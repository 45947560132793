// import apiClientBase from '@/api/api-client-base'
// import GetMemberRequest from '@/api/apimodel/get-member-request'
// import GetMemberAddInfoRequest from '@/api/apimodel/get-member-add-info-request'
// import GetPlanningWeeksRequest from '@/api/apimodel/get-planning-weeks-request'
// import GetPointBalanceRequest from '@/api/apimodel/get-point-balance-request'
// import SendMailRequest from '@/api/apimodel/send-mail-request'
// import memberidApiClient from '@/api/memberid-api-client'
import Member from '@/models/member'
// import Config from '@/config'
import storage from '@/storage'
import store from '@/store'
// import router from '@/router'
// import Vue from 'vue'
import MutationTypes from '@/store/mutation-types'

const state = {
  member: new Member(),
  hasMemberData: false,
}

const getters = {
  member: state => state.member,
  hasMemberData: state => state.hasMemberData,
}

const mutations = {
  [MutationTypes.SET_MEMBER](state, { member }) {
    state.member = member
    state.hasMemberData = true
  },
}

const actions = {
  setMember({ commit }, { val }) {
    commit(MutationTypes.SET_MEMBER, { member: val })
    storage.setMemberInfo(val)
  },
  // 組合員情報
  getMember({ commit }) {
    const member = store.getters.member
    if (!store.getters.hasMemberData) {
      const stg = storage.getMemberInfo()
      if (stg) {
        const mm = new Member()
        mm.firstName = stg.firstName
        mm.lastName = stg.lastName
        mm.firstNameKana = stg.firstNameKana
        mm.lastNameKana = stg.lastNameKana
        mm.birthDate = stg.birthDate
        mm.sex = stg.sex
        mm.zipCode = stg.zipCode
        mm.address1 = stg.address1
        mm.address2 = stg.address2
        mm.address3 = stg.address3
        mm.phoneNumber = stg.phoneNumber
        mm.email = stg.email
        mm.selectedChildService = stg.selectedChildService
        mm.selectedDelivery = stg.selectedDelivery
        mm.addressList = stg.addressList
        commit(MutationTypes.SET_MEMBER, { member: mm })
        return mm
      }
    }
    return member
  },

  hasMember() {
    return storage.getMemberInfo() !== null
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
