import Constants from '@/lib/constants'

export default class Capital {
  // ラジオボタン 0:クレジットカード 1: 口座振替
  selectedPay = 0
  // 計算用口数
  quantity = 0

  // 取得する口数
  strQuantity = ''
  // 金額
  amount = 0

  memberCode = '' // 組合員番号
  memberCardId = '' //組合員証番号

  shoporderNumber = '' // 請求番号
  // カード情報は保持しない
  // cardHolder = ""
  // cardNumber = ""
  // expirationDate = ""
  // securityCode = ""

  // SMBC連携用の組合員番号を14桁の0埋めした値を取得する
  getStrMemberCardId() {
    return this.memberCardId.padStart(14, '0')
  }
  setQuantity(val) {
    this.strQuantity = val
    this.quantity = Number(this.strQuantity)
    const unit = Constants.CAPITAL_CONTRIBUTION_UNIT
    this.amount = unit * this.quantity
  }

  getAmount() {
    // const unit = Constants.CAPITAL_CONTRIBUTION_UNIT
    // this.quantity = Number(this.strQuantity)
    // this.amount = unit * this.quantity
    return this.amount.toString()
  }
}
