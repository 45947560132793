<template>
  <div class="pc-wrap memberid-signup-main">
    <v-container class="mt-1 mb-10">
      <h3 class="memberid-black-600--text title font-weight-bold">組合員情報の入力</h3>
      <p class="mt-2">組合員加入に必要な情報の入力をお願いします。</p>
      <v-form v-model="entryValid" ref="memberForm" autocomplete="off">
        <double-text-field
          :valueLeft.sync="member.lastName"
          :valueRight.sync="member.firstName"
          :inputTitle="name.title"
          :requiredFlag="name.required"
          :placeholderLeft="name.placeholderLeft"
          :placeholderRight="name.placeholderRight"
          :caption="name.caption"
          :leftErrorMessage="validLastName()"
          :rightErrorMessage="validFirstName()"
          :bothErrorMessage="validName()"
          v-on:blurRight="$v.member.firstName.$touch()"
          v-on:blurLeft="$v.member.lastName.$touch()"
        />
        <double-text-field
          :valueLeft.sync="member.lastNameKana"
          :valueRight.sync="member.firstNameKana"
          :inputTitle="kana.title"
          :requiredFlag="kana.required"
          :placeholderLeft="kana.placeholderLeft"
          :placeholderRight="kana.placeholderRight"
          :caption="kana.caption"
          :leftErrorMessage="validLastNameKana()"
          :rightErrorMessage="validFirstNameKana()"
          :bothErrorMessage="validNameKana()"
          v-on:blurRight="$v.member.firstNameKana.$touch()"
          v-on:blurLeft="$v.member.lastNameKana.$touch()"
        />
        <date-picker
          :placeholder="birthdate.placeholder"
          :inputTitle="birthdate.title"
          :requiredFlag="birthdate.required"
          :inputDate.sync="member.birthDate"
          :defaultDateParam="birthdate.defaultBirthdate"
          v-on:blur="$v.member.birthDate.$touch()"
          :errorMessages="validBirthDate()"
          :minDateParam="minDate"
        />
        <radio-button
          :inputTitle="sex.title"
          :requiredFlag="sex.required"
          :placementTextList="sex.placementTextList"
          :value.sync="member.sex"
          v-on:change="$v.member.sex.$touch()"
          :errorMessages="validSex()"
        />
        <zip-code-component
          :value.sync="member.zipCode"
          :addressList.sync="member.addressList"
          v-on:custom="
            clearAddress()
            $v.member.address1.$touch()
          "
        />
        <select-box
          :inputTitle="address1.title"
          :requiredFlag="address1.required"
          :placeholder="address1.placeholder"
          :value.sync="member.address1"
          :items="member.addressList"
          :isDisabled="member.addressList && member.addressList.length === 0"
          :errorMessages="validAddress1()"
          ><template v-slot:message>
            <div class="caption">
              あてはまる住所が選択肢にない場合は<span
                role="button"
                class="text-decoration-underline"
                @click="isShowAddress1Navigation = true"
                >こちら</span
              >
            </div>
            <v-sheet v-if="isShowAddress1Navigation" class="rounded-lg memberid-black-100 pa-4 mt-1">
              <span class="caption"
                >誠に恐れ入りますが、オンラインによる組合員加入ができません。<br
                  v-if="!isMobile"
                />お手数をおかけしますが、下記の相談窓口までお問い合わせください。<br
                  v-if="!isMobile"
                />手続きを終了する場合は画面を閉じてください。</span
              >
              <h4 class="pt-1">
                【組合員加入のお問い合わせ】<br />
                <a href="tel:0120125913" class="memberid-black-500--text text-decoration-underline">0120-125-913</a
                >（月〜土 10:00～17:00）
              </h4>
            </v-sheet>
          </template></select-box
        >
        <single-valid-text-field
          :inputTitle="address2.title"
          :requiredFlag="address2.required"
          :placeholder="address2.placeholder"
          :value.sync="member.address2"
          :errorMessages="validAddress2()"
          :caption="address2.caption"
          v-on:blur="$v.member.address2.$touch()"
        />
        <single-valid-text-field
          :inputTitle="address3.title"
          :placeholder="address3.placeholder"
          :value.sync="member.address3"
          :errorMessages="validAddress3()"
          v-on:blur="$v.member.address3.$touch()"
        />
        <single-valid-text-field
          :inputTitle="phoneNumber.title"
          :requiredFlag="phoneNumber.required"
          :placeholder="phoneNumber.placeholder"
          :value.sync="member.phoneNumber"
          :errorMessages="validPhoneNumber()"
          :disabledFlag="loginIsTel"
          :caption="phoneNumber.caption"
          v-on:blur="$v.member.phoneNumber.$touch()"
        />
        <single-valid-text-field
          :inputTitle="email.title"
          :placeholder="email.placeholder"
          :value.sync="member.email"
          :errorMessages="validEmail()"
          :disabledFlag="loginIsEmail"
          :caption="email.caption"
          underText="コープさっぽろからお得な情報をお送りします。"
          v-on:blur="$v.member.email.$touch()"
        />
        <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
          <radio-button
            :inputTitle="delivery.title"
            :requiredFlag="delivery.required"
            :placementTextList="delivery.placementTextList"
            :value.sync="member.selectedDelivery"
            v-on:change="$v.member.selectedDelivery.$touch()"
            :errorMessages="validDelivery()"
          />
          <v-layout class="my-3">
            <p class="mb-0 memberid-secondary--text subtitle-1 text-indention font-weight-bold text-decoration-underline">宅配トドックについて詳しく見る↓</p>
          </v-layout>
          <v-layout class="my-3" :class="{ 'justify-center': isMobile }">
            <a href="https://todok.sapporo.coop/static/todok11a/index.html" target="_blank" rel="noopener">
              <v-img max-width="343px" :src="require('@/assets/bunner_delivery_description.png')" alt="毎週決まった曜日・時間に届きます！コープの宅配トドック 初めての方はこちら"/>
            </a>
          </v-layout>
        </v-sheet>
        <v-sheet class="rounded-lg memberid-black-100 pa-4 mt-8">
          <v-row>
            <v-col class="pt-4">
              <common-input-title :title="child.title" :requiredFlag="child.required" :caption="child.caption" />
            </v-col>
          </v-row>
          <v-layout class="my-3" :class="{ 'justify-center': isMobile }">
            <v-img max-width="343px" :src="require('@/assets/child_support.png')" />
          </v-layout>

          <v-radio-group
            class="ml-3 mt-1"
            v-model="member.selectedChildService"
            :error="childHasError"
            :disabled="member.selectedDelivery !== 1"
            column
            hide-details
            @change="
              if (member.selectedDelivery !== 1) return
              $v.member.selectedChildService.$touch()
            "
          >
            <v-radio
              color="memberid-primary"
              class="mt-3 mb-3"
              v-for="(text, i) in child.placementTextList"
              :key="i"
              :value="i"
            >
              <template v-slot:label>
                <div
                  v-bind:class="[
                    (member.selectedDelivery !== 1) === true ? 'memberid-black-400--text' : 'memberid-black-600--text',
                  ]"
                >
                  {{ text }}
                </div>
              </template>
            </v-radio>
          </v-radio-group>
          <v-row v-if="childHasError">
            <v-col>
              <error-messages :isShow="childHasError" :messages="validChild()"></error-messages>
            </v-col>
          </v-row>

          <p
            class="mx-7 px-3 pb-3 subtitle-2"
            v-bind:class="[member.selectedDelivery !== 1 ? 'memberid-black-400--text ' : 'memberid-black-500--text ']"
          >
            次の画面でお子様の情報をご入力いただきます。母子手帳や保険証など、妊娠中やお子さまの年齢を確認できるものをご準備ください。
          </p>
        </v-sheet>
      </v-form>
      <rounded-button :buttonText="nextButtonText" :nextPage="checkBeforeNextPage" :isdisabled="!entryValid" />

      <div class="d-flex justify-end pt-10">
        <span class="caption memberid-black-400--text"> ver {{ getAppVersion }} </span>
      </div>
    </v-container>
    <v-dialog persistent v-model="memberidDuplicateDialog" max-width="450">
      <v-card>
        <v-card-title class="memberid-black-600--text h6 font-weight-bold">
          すでに組合員加入されていませんか？
        </v-card-title>
        <v-card-text class="memberid-black-400--text subtitle-2">
          同じお名前、生年月日のご登録が確認されました。もしかするとすでに組合員の可能性があります。重複での加入の場合、脱退等の手続きのため、ご連絡させていただくことがございます。
        </v-card-text>
        <v-card-actions class="pt-6">
          <v-spacer />
          <v-btn @click="notEnter()" text class="memberid-black-400--text font-weight-bold subtitle-1"
            >今は加入しない</v-btn
          >
          <v-btn
            @click="
              memberidDuplicateDialog = false
              nextPage()
            "
            text
            class="memberid-primary--text font-weight-bold subtitle-1"
            >加入を続ける</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommonMixin from '@/components/mixins/common-mixin'
import CommonInputTitle from '@/components/InputTitle'
import SingleValidTextField from '@/components/SingleValidTextFieldComponent'
import DoubleTextField from '@/components/DoubleTextFieldComponent'
import DatePicker from '@/components/DatePickerComponent'
import RadioButton from '@/components/RadioButtonComponent'
import RoundedButton from '@/components/RoundedButtonComponent'
import SelectBox from '@/components/SelectComponent'
import ZipCodeComponent from '@/components/ZipCodeComponent'
import ErrorMessages from '@/components/ErrorMessageListComponent'

import Member from '@/models/member'
import DuplicateRequest from '@/api/apimodel/duplicate-request'
import memberidApiClient from '@/api/memberid-api-client'
import { validationMixin } from 'vuelidate'
import { required, numeric, minLength, maxLength, email } from 'vuelidate/lib/validators'
import Config from '@/config'
import store from '@/store'
import storage from '@/storage'
import Encoding from 'encoding-japanese'
import Vue from 'vue'
import {
  checkZenSpace,
  checkShiftJis,
  zenKana,
  isShowPhoneNumberError,
  zenkaku,
  zenkakuWithSpace,
  length50,
  length60,
} from '@/lib/validations'

export default {
  name: 'MenmberEntry',
  mixins: [CommonMixin, validationMixin],
  components: {
    SingleValidTextField,
    DoubleTextField,
    DatePicker,
    RadioButton,
    RoundedButton,
    SelectBox,
    ZipCodeComponent,
    CommonInputTitle,
    ErrorMessages,
  },
  beforeRouteEnter(to, from, next) {
    // 直接パスを叩かれたら入り口に飛ばす
    if (!from || from.name === null) {
      next({ name: 'MemberEntrance' })
    } else {
      next()
    }
  },

  validations() {
    let validObj = {
      member: {
        lastName: { required, zenkaku, checkShiftJis },
        firstName: { required, zenkaku, checkShiftJis },
        firstNameKana: { required, zenKana, checkShiftJis },
        lastNameKana: { required, zenKana, checkShiftJis },
        birthDate: { required },
        sex: { required },
        zipCode: { required },
        address1: { required, length50 },
        address2: { required, zenkaku, checkZenSpace, checkShiftJis, length50 },
        address3: { zenkakuWithSpace, checkShiftJis, length50 },
        phoneNumber: { required, numeric, minLength: minLength(10), maxLength: maxLength(11), isShowPhoneNumberError },
        email: { email },
        selectedDelivery: { required },
      },
    }
    if (this.member.selectedDelivery === 1) {
      validObj.member.selectedChildService = { required }
    }
    return validObj
  },
  data() {
    return {
      getAppVersion: Config.getAppVersion,
      lastViewIsConfirm: false,
      nextButtonText: '次へ',
      entryValid: false,
      memberidDuplicateDialog: false,
      loginIsEmail: false,
      loginIsTel: false,

      name: {
        title: 'お名前',
        required: true,
        placeholderLeft: '生協',
        placeholderRight: '花子',
        caption: '※全角',
      },
      kana: {
        title: 'フリガナ',
        required: true,
        placeholderLeft: 'セイキョウ',
        placeholderRight: 'ハナコ',
        caption: '※全角カナ',
      },
      birthdate: {
        title: '生年月日',
        required: true,
        placeholder: 'クリックで生年月日を入力',
        defaultBirthdate: new Date(new Date().getFullYear() - 40, 0, 1, 9, 0, 0),
      },
      sex: { title: '性別', required: true, placementTextList: ['女性', '男性'] },
      address1: {
        title: '市区町村',
        required: true,
        placeholder: '選択してください',
      },
      address2: {
        title: '以降の住所',
        required: true,
        placeholder: '２番地１−２',
        caption: '※全角',
      },
      address3: {
        title: '建物名・部屋番号',
        required: true,
        placeholder: 'トドックアパート１０１',
      },
      phoneNumber: {
        title: '連絡先電話番号',
        required: true,
        placeholder: '09012345678',
        caption: '※ハイフン無し、半角数字'
      },
      email: {
        title: 'メールアドレス',
        required: true,
        placeholder: 'todok@sapporo.coop',
        caption: '※半角英数字',
      },
      delivery: {
        title: '宅配トドック',
        required: true,
        placementTextList: ['今は利用しない', '利用を希望する'],
      },
      child: {
        title: '子育てサポート',
        required: true,
        caption: '妊娠中〜15歳未満のお子さまがいる場合、宅配トドックの子育てサポートをご利用いただけます。',
        placementTextList: ['利用しない', '利用を希望する'],
      },
      member: { type: Member },
      isShowAddress1Navigation: false,
    }
  },
  created() {},
  async mounted() {
    if (storage.lastViewIsConfirm()) {
      this.lastViewIsConfirm = true
      this.nextButtonText = '内容確認へ戻る'
    }
    this.member = new Member()
    const mm = await store.dispatch('getMember')
    if (mm) {
      this.member.firstName = mm.firstName
      this.member.lastName = mm.lastName
      this.member.firstNameKana = mm.firstNameKana
      this.member.lastNameKana = mm.lastNameKana
      this.member.birthDate = mm.birthDate
      this.member.sex = mm.sex
      this.member.zipCode = mm.zipCode
      this.member.address1 = mm.address1
      this.member.address2 = mm.address2
      this.member.address3 = mm.address3
      this.member.phoneNumber = mm.phoneNumber
      this.member.email = mm.email
      this.member.selectedChildService = mm.selectedChildService
      this.member.selectedDelivery = mm.selectedDelivery
      this.member.addressList = mm.addressList
    }
    const user = this.$auth.user
    const loginId = user.name
    if (loginId.includes('@')) {
      this.member.email = loginId
      this.loginIsEmail = true
    } else {
      this.member.phoneNumber = loginId.replace(/\+81/g, '0')
      this.loginIsTel = true
    }
  },
  methods: {
    validFirstName() {
      if (!this.$v.member.firstName.$dirty) return ''
      if (!this.$v.member.firstName.required) return `${this.name.title}（名）をご入力ください`
      if (!this.$v.member.firstName.zenkaku) return `${this.name.title}（名）全角で入力してください`
      if (!checkShiftJis(this.member.firstName)) return `${this.name.title}（名）に入力できない文字が含まれています`
      return ''
    },
    validLastName() {
      if (!this.$v.member.lastName.$dirty) return ''
      if (!this.$v.member.lastName.required) return `${this.name.title}（姓）をご入力ください`
      if (!this.$v.member.lastName.zenkaku) return `${this.name.title}（姓）全角で入力してください`
      if (!checkShiftJis(this.member.lastName)) return `${this.name.title}（姓）に入力できない文字が含まれています`
      return ''
    },
    validName() {
      if (this.member.lastName && this.member.lastName.length + this.member.firstName.length > 9) {
        return `${this.name.title}（姓） + ${this.name.title}（名） は９文字以下で入力してください`
      }
      return ''
    },
    validFirstNameKana() {
      if (!this.$v.member.firstNameKana.$dirty) return ''
      if (!this.$v.member.firstNameKana.required) return `${this.kana.title}（名）をご入力ください`
      if (!this.$v.member.firstNameKana.zenKana) return `${this.kana.title}（名）全角カナで入力してください`
      if (!checkShiftJis(this.member.firstNameKana)) return `${this.kana.title}（名）に入力できない文字が含まれています`
      return ''
    },
    validLastNameKana() {
      if (!this.$v.member.lastNameKana.$dirty) return ''
      if (!this.$v.member.lastNameKana.required) return `${this.kana.title}（姓）をご入力ください`
      if (!this.$v.member.lastNameKana.zenKana) return `${this.kana.title}（姓）全角カナで入力してください`
      if (!checkShiftJis(this.member.lastNameKana)) return `${this.kana.title}（姓）に入力できない文字が含まれています`
      return ''
    },
    validNameKana() {
      if (this.member.lastNameKana && this.member.lastNameKana.length + this.member.firstNameKana.length > 19) {
        return `${this.kana.title}（姓） + ${this.kana.title}（名） は１９文字以下で入力してください`
      }
      return ''
    },
    validBirthDate() {
      const errors = []
      if (!this.$v.member.birthDate.$dirty) return errors
      !this.$v.member.birthDate.required && errors.push(`${this.birthdate.title}を選択してください`)
      return errors
    },
    validSex() {
      const errors = []
      if (!this.$v.member.sex.$dirty) return errors
      !this.$v.member.sex.required && errors.push(`${this.sex.title}を選択してください`)
      return errors
    },
    validAddress1() {
      const errors = []
      if (!this.$v.member.address1.$dirty) return errors
      this.member.addressList.length === 0 &&
        errors.push(`住所情報が取得できませんでした。ご入力された郵便番号にお間違いがないかご確認ください。`)
      !this.$v.member.address1.required && errors.push(`${this.address1.title}をご入力ください`)
      !this.$v.member.address1.length50 && errors.push(`${this.address1.title}は50文字以内でご入力ください`)
      !checkShiftJis(this.member.address1) && errors.push(`${this.address1.title}に入力できない文字が含まれています`)
      errors.push(...this.validAddress())
      return errors
    },
    validAddress2() {
      const errors = []
      if (!this.$v.member.address2.$dirty) return errors
      !this.$v.member.address2.required && errors.push(`${this.address2.title}をご入力ください`)
      !this.$v.member.address2.zenkaku && errors.push(`${this.address2.title}は全角でご入力ください`)
      !this.$v.member.address2.checkZenSpace && errors.push(`${this.address2.title}に全角スペースが含まれています`)
      !this.$v.member.address2.length50 && errors.push(`${this.address2.title}は50文字以内でご入力ください`)
      !checkShiftJis(this.member.address2) && errors.push(`${this.address2.title}に入力できない文字が含まれています`)
      return errors
    },
    validAddress3() {
      const errors = []
      !this.$v.member.address3.zenkakuWithSpace && errors.push(`${this.address3.title}は全角でご入力ください`)
      // !this.$v.member.address3.checkZenSpace && errors.push(`${this.address3.title}に全角スペースが含まれています`)
      !this.$v.member.address3.length50 && errors.push(`${this.address3.title}は50文字以内でご入力ください`)
      !checkShiftJis(this.member.address3) && errors.push(`${this.address3.title}に入力できない文字が含まれています`)
      return errors
    },
    validAddress() {
      const errors = []
      !length60(this.member.address1 + this.member.address2 + this.member.address3) &&
        errors.push(`住所は６０文字以内でご入力ください`)
      return errors
    },
    validPhoneNumber() {
      const errors = []
      if (!this.$v.member.phoneNumber.$dirty) return errors
      !this.$v.member.phoneNumber.required && errors.push(`${this.phoneNumber.title}をご入力ください`)
      !this.$v.member.phoneNumber.numeric && errors.push('半角数字でご入力ください')
      !this.$v.member.phoneNumber.minLength && errors.push('文字数が不足しています')
      !this.$v.member.phoneNumber.maxLength && errors.push('文字数が多いです')
      this.member.phoneNumber &&
        !this.$v.member.phoneNumber.isShowPhoneNumberError &&
        errors.push('ご登録できない電話番号です')
      return errors
    },
    validEmail() {
      const errors = []
      if (!this.$v.member.email.$dirty) return errors
      !this.$v.member.email.email && errors.push('メールアドレスの形式が不正です。')
      return errors
    },
    validDelivery() {
      const errors = []
      if (!this.$v.member.selectedDelivery.$dirty) return errors
      !this.$v.member.selectedDelivery.required && errors.push(`${this.delivery.title}を選択してください`)
      return errors
    },
    validChild() {
      const errors = []
      if (this.member.selectedDelivery !== 1 || !this.$v.member.selectedChildService.$dirty) return errors
      !this.$v.member.selectedChildService.required && errors.push(`${this.child.title}を選択してください`)
      return errors
    },
    validate() {
      this.$v.$touch()
      return this.$v.$invalid || this.validAddress().length > 0 || (this.validName() + this.validNameKana()).length > 0
    },

    async checkBeforeNextPage() {
      if (this.validate()) {
        return
      }
      Vue.$startLoading()

      // 重複チェック
      const duplicateRequest = new DuplicateRequest()
      duplicateRequest.lastName = this.member.lastName
      duplicateRequest.firstName = this.member.firstName
      duplicateRequest.birthday = this.member.birthDate
      duplicateRequest.sexuality = this.member.sex === 0 ? '2' : '1' //画面は女性:0 男性:1 リクエストは女性 : 2 男性 : 1
      await memberidApiClient
        .isDuplicated(duplicateRequest)
        .then((response) => {
          if (response) {
            let isDuplicated = response.data.searchResult
            if (isDuplicated) {
              this.memberidDuplicateDialog = true
              return
            }
            this.nextPage()
          }
        })
        .catch((error) => {
          duplicateRequest.defaultErrorProcess(error)
        })
        .finally(() => {
          Vue.$endLoading()
        })
    },
    nextPage() {
      let routerName = ''
      this.setDateToStore()
      if (storage.lastViewIsConfirm()) {
        //
        if (this.member.selectedChildService === 1 && !storage.getChildInfo()) {
          routerName = 'ChildInformation'
        } else if (this.member.selectedDelivery === 1 && !storage.getDeliveryInfo()) {
          routerName = 'DeliveryInformation'
        } else {
          routerName = 'MemberConfirm'
        }
      } else {
        if (this.member.selectedChildService === 1) {
          routerName = 'ChildInformation'
        } else if (this.member.selectedDelivery === 1) {
          routerName = 'DeliveryInformation'
        } else {
          routerName = 'CapitalEntry'
        }
      }

      store.dispatch('routerPushWithFromQuery', { name: routerName })
    },
    // 新しく住所リストを取得したら変数をリセットする
    clearAddress() {
      this.member.address1 = ''
    },
    setDateToStore() {
      const mmm = new Member()
      mmm.firstName = this.member.firstName
      mmm.lastName = this.member.lastName
      mmm.firstNameKana = this.member.firstNameKana
      mmm.lastNameKana = this.member.lastNameKana
      mmm.birthDate = this.member.birthDate
      mmm.sex = this.member.sex
      mmm.zipCode = this.member.zipCode
      mmm.address1 = this.member.address1
      // 半角空白を全角空白に置換した上で全角文字への変換を行う
      mmm.address2 = Encoding.toZenkakuCase(this.member.address2.replace(/ /g, '　'))
      mmm.address3 = Encoding.toZenkakuCase(this.member.address3.replace(/ /g, '　'))
      mmm.phoneNumber = this.member.phoneNumber
      mmm.email = this.member.email
      mmm.selectedChildService = this.member.selectedChildService
      mmm.selectedDelivery = this.member.selectedDelivery
      mmm.addressList = this.member.addressList
      store.dispatch('setMember', { val: mmm })
    },
    notEnter() {
      this.memberidDuplicateDialog = false
      window.location = 'https://www.sapporo.coop/'
    },
  },
  watch: {
    // member: {
    //   deep: true,
    //   handler: function() {
    //     const mmm = new Member()
    //     mmm.firstName = this.member.firstName
    //     mmm.lastName = this.member.lastName
    //     mmm.firstNameKana = this.member.firstNameKana
    //     mmm.lastNameKana = this.member.lastNameKana
    //     mmm.birthDate = this.member.birthDate
    //     mmm.sex = this.member.sex
    //     mmm.zipCode = this.member.zipCode
    //     mmm.address1 = this.member.address1
    //     mmm.address2 = Encoding.toZenkakuCase(this.member.address2)
    //     mmm.address3 = Encoding.toZenkakuCase(this.member.address3)
    //     mmm.phoneNumber = this.member.phoneNumber
    //     mmm.email = this.member.email
    //     mmm.selectedChildService = this.member.selectedChildService
    //     mmm.selectedDelivery = this.member.selectedDelivery
    //     mmm.addressList = this.member.addressList
    //     store.dispatch('setMember', { val: mmm })
    //   },
    // },
  },
  computed: {
    childHasError: function () {
      const errors = this.validChild()
      return typeof errors !== 'undefined' && errors.length > 0
    },
    minDate() {
      const dt = new Date()
      dt.setFullYear(dt.getFullYear() - 120)
      return dt
    },
  },
}
</script>

<style>
.memberid-header-bottom {
  border-bottom: 1px solid #cfcfcf;
}
</style>
