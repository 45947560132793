import apiClientBase from '@/api/api-client-base'
import Config from '@/config'

class MemberidApiClient {
  // 市区町村リスト検索
  //TODO 中身ができたら書き換える。今はまだ宅配の方を使う
  async getAddressList(zipCode) {
    const endpoint = this.getEndpointBase() + '/addresslist/' + zipCode
    return (await apiClientBase.getAuthenticatedClient()).get(endpoint)
  }

  // 組合員管理システム組合員重複チェック
  async isDuplicated(requestParam) {
    const endpoint = this.getEndpointBase() + '/isduplicated'
    return (await apiClientBase.getAuthenticatedClient()).post(endpoint, requestParam)
  }

  // 組合員情報登録予約
  async reserveMember(requestParam) {
    const endpoint = this.getEndpointBase() + '/reservemember'
    return (await apiClientBase.getAuthenticatedClient()).put(endpoint, requestParam)
  }

  // 組合員番号採番
  async generateMembercode() {
    const endpoint = this.getEndpointBase() + '/generatemembercode'
    return (await apiClientBase.getAuthenticatedClient()).post(endpoint)
  }

  // 請求番号採番
  async generateShoporderNumber() {
    const endpoint = this.getEndpointBase() + '/generateshopordernumber'
    return (await apiClientBase.getAuthenticatedClient()).post(endpoint)
  }

  // SMBC情報取得
  async getPaymentServiceConfig() {
    const endpoint = this.getEndpointBase() + '/paymentserviceconfig'
    return (await apiClientBase.getAuthenticatedClient()).get(endpoint)
  }

  // マルペイ情報取得
  async getPaymentServiceConfigForCc() {
    const endpoint = this.getEndpointBase() + '/paymentserviceconfigforcc'
    return (await apiClientBase.getAuthenticatedClient()).get(endpoint)
  }

  // マルペイ与信登録
  async putCredit(requestParam) {
    const endpoint = this.getEndpointBase() + '/putcredit'
    return (await apiClientBase.getAuthenticatedClient()).put(endpoint, requestParam)
  }

  // マルペイ請求確定
  async submitClaim(requestParam) {
    const endpoint = this.getEndpointBase() + '/submitclaim'
    return (await apiClientBase.getAuthenticatedClient()).put(endpoint, requestParam)
  }

  // メンテナンス情報取得
  getMaintenanceInfo() {
    const endpoint = this.getEndpointBase() + '/public/maintenanceinfo'
    return apiClientBase.getHttpClient().get(endpoint)
  }

  getEndpointBase() {
    return Config.getMemberidEndpoint
  }
}

const memberidApiClient = new MemberidApiClient()
export default memberidApiClient
