// import Auth0Constants from '@/lib/auth0-constants'
// import store from '@/store'

/**
 * Storage
 */
class Storage {
  setUnionMember(value) {
    if (value) {
      sessionStorage.setItem(StorageKey.UNION_MEMBER_CHECK, value)
    } else {
      sessionStorage.removeItem(StorageKey.UNION_MEMBER_CHECK)
    }
  }
  isUnionMember() {
    const value = sessionStorage.getItem(StorageKey.UNION_MEMBER_CHECK)
    return value === 'true'
  }

  // 以下追加
  // 登録完了後全部消す
  allClear() {
    this.removeMemberInfo()
    this.removeDeliveryInfo()
    this.removeChildInfo()
    this.removeCapitalInfo()
    this.removeLastViewIsConfirm()
    this.removeAccountTransferStatus()
    this.removeShopOrderNumber()
    this.removeAccessInfo()
  }
  allExtraData() {
    this.removeCompMemberCode()
    this.removeCompMemberName()
  }
  setMemberInfo(value) {
    sessionStorage.setItem(StorageKey.MEMBER_INFORMATION, JSON.stringify(value))
  }
  removeMemberInfo() {
    sessionStorage.removeItem(StorageKey.MEMBER_INFORMATION)
  }
  getMemberInfo() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.MEMBER_INFORMATION)) {
      return JSON.parse(sessionStorage.getItem(StorageKey.MEMBER_INFORMATION))
    }
    return null
  }

  setDeliveryInfo(value) {
    sessionStorage.setItem(StorageKey.DELIVERY_INFORMATION, JSON.stringify(value))
  }
  removeDeliveryInfo() {
    sessionStorage.removeItem(StorageKey.DELIVERY_INFORMATION)
  }
  getDeliveryInfo() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.DELIVERY_INFORMATION)) {
      return JSON.parse(sessionStorage.getItem(StorageKey.DELIVERY_INFORMATION))
    }
    return null
  }

  setChildInfo(value) {
    sessionStorage.setItem(StorageKey.CHILD_INFORMATION, JSON.stringify(value))
  }
  removeChildInfo() {
    sessionStorage.removeItem(StorageKey.CHILD_INFORMATION)
  }
  getChildInfo() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.CHILD_INFORMATION)) {
      return JSON.parse(sessionStorage.getItem(StorageKey.CHILD_INFORMATION))
    }
    return null
  }

  setCapitalInfo(value) {
    sessionStorage.setItem(StorageKey.CAPITAL_INFORMATION, JSON.stringify(value))
  }
  removeCapitalInfo() {
    sessionStorage.removeItem(StorageKey.CAPITAL_INFORMATION)
  }
  getCapitalInfo() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.CAPITAL_INFORMATION)) {
      return JSON.parse(sessionStorage.getItem(StorageKey.CAPITAL_INFORMATION))
    }
    return null
  }


  setAccessInfo(value) {
    sessionStorage.setItem(StorageKey.ACCESS_INFO, JSON.stringify(value))
  }
  removeAccessInfo() {
    sessionStorage.removeItem(StorageKey.ACCESS_INFO)
  }
  getAccessInfo() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.ACCESS_INFO)) {
      return JSON.parse(sessionStorage.getItem(StorageKey.ACCESS_INFO))
    }
    return null
  }

  // 銀行口座を登録したかどうかを保持する
  // true : 銀行口座登録済み false: 銀行口座登録なし
  setAccountTransferStatus(value) {
    sessionStorage.setItem(StorageKey.REGIST_ACCOUNT_TRANSFER_STATUS, value)
  }
  removeAccountTransferStatus() {
    sessionStorage.removeItem(StorageKey.REGIST_ACCOUNT_TRANSFER_STATUS)
  }
  isAccountTransferStatus() {
    const status = sessionStorage.getItem(StorageKey.REGIST_ACCOUNT_TRANSFER_STATUS)
    return JSON.parse(status)
  }

  // 前画面が確認画面だった場合に保持しておく
  // 遷移する時に削除すること
  setLastViewIsConfirm(value) {
    sessionStorage.setItem(StorageKey.LAST_VIEW_IS_CONFIRM, value)
  }
  removeLastViewIsConfirm() {
    sessionStorage.removeItem(StorageKey.LAST_VIEW_IS_CONFIRM)
  }
  lastViewIsConfirm() {
    const status = sessionStorage.getItem(StorageKey.LAST_VIEW_IS_CONFIRM)
    return JSON.parse(status)
  }

  // 請求番号
  setShopOrderNumber(value) {
    sessionStorage.setItem(StorageKey.SHOP_ORDER_NUMBER, value)
  }
  removeShopOrderNumber() {
    sessionStorage.removeItem(StorageKey.SHOP_ORDER_NUMBER)
  }
  getShopOrderNumber() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.SHOP_ORDER_NUMBER)) {
      return sessionStorage.getItem(StorageKey.SHOP_ORDER_NUMBER)
    }
    return null
  }

  // 完了後の組合員番号
  setCompMemberCode(value) {
    sessionStorage.setItem(StorageKey.COMP_MEMBER_CODE, value)
  }
  removeCompMemberCode() {
    sessionStorage.removeItem(StorageKey.COMP_MEMBER_CODE)
  }
  getCompMemberCode() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.COMP_MEMBER_CODE)) {
      return sessionStorage.getItem(StorageKey.COMP_MEMBER_CODE)
    }
    return null
  }
  // 完了後の組合員氏名
  setCompMemberName(value) {
    sessionStorage.setItem(StorageKey.COMP_MEMBER_NAAE, value)
  }
  removeCompMemberName() {
    sessionStorage.removeItem(StorageKey.COMP_MEMBER_NAAE)
  }
  getCompMemberName() {
    if (Object.hasOwnProperty.call(sessionStorage, StorageKey.COMP_MEMBER_NAAE)) {
      return sessionStorage.getItem(StorageKey.COMP_MEMBER_NAAE)
    }
    return null
  }

  /**
   * 営業担当者コードのSessionStorageへの保管
   * 営業担当者コードはサイトアクセス時のQueryStringから与えられることを想定する
   */
  saveSalesPersonEmployeeCode(salesPersonEmployeeCode) {
    sessionStorage.setItem(StorageKey.SALES_PERSON_EMPLOYEE_CODE, salesPersonEmployeeCode)
  }
  /**
   * 営業担当者コードのSessionStorageへの保管
   * 営業担当者コードはサイトアクセス時のQueryStringから与えられることを想定する
   */
  getSalesPersonEmployeeCode() {
    return sessionStorage.getItem(StorageKey.SALES_PERSON_EMPLOYEE_CODE)
  }

  /**
   * アクセス元
   */
  setFrom(from) {
    if (from) {
      sessionStorage.setItem(StorageKey.FROM, from)
    } else {
      sessionStorage.removeItem(StorageKey.FROM, from)
    }
  }
  /**
   * アクセス元
   */
  getFrom() {
    const from = sessionStorage.getItem(StorageKey.FROM)
    if (from) {
      return from
    }
    return ''
  }
}

/**
 * StorageKey
 */
class StorageKey {
  static UNION_MEMBER_CHECK = 'UNION_MEMBER_CHECK'

  static MEMBER_INFORMATION = 'MEMBER_INFORMATION'
  static DELIVERY_INFORMATION = 'DELIVERY_INFORMATION'
  static CHILD_INFORMATION = 'CHILD_INFORMATION'
  static CAPITAL_INFORMATION = 'CAPITAL_INFORMATION'
  static ACCESS_INFO = 'ACCESS_INFO'
  static REGIST_ACCOUNT_TRANSFER_STATUS = 'REGIST_ACCOUNT_TRANSFER_STATUS'
  static LAST_VIEW_IS_CONFIRM = 'LAST_VIEW_IS_CONFIRM'
  static SHOP_ORDER_NUMBER = 'SHOP_ORDER_NUMBER'
  static COMP_MEMBER_CODE = 'COMP_MEMBER_CODE'
  static COMP_MEMBER_NAAE = 'COMP_MEMBER_NAAE'
  // このサイトに誘導した営業担当者のコード
  static SALES_PERSON_EMPLOYEE_CODE = 'SALES_PERSON_EMPLOYEE_CODE'
  // アクセス元
  static FROM = 'FROM'
}

const storage = new Storage()
export default storage
