import { getInstance as getAuth0Instance } from '@/auth'
import packageJson from '@/../package.json'
// env切替
// const env = Environment.Local
const env = process.env

export default class Config {
  static get currentEnv() {
    return env.VUE_APP_CURRENT_ENV
  }

  static get getEnvName() {
    const auth0 = getAuth0Instance()
    if (this.isTesting()) {
      return 'テスト環境(' + env.VUE_APP_CURRENT_ENV + ')' + ' ** auth -> ' + auth0.isAuthenticated + ' **'
    }
    return ''
  }

  static get getDeliveryEndpoint() {
    return env.VUE_APP_DELIVERY_ENDPOINT
  }

  static get getMemberidEndpoint() {
    return env.VUE_APP_MEMBERID_ENDPOINT
  }

  static get getTodokSiteEndpoint() {
    return env.VUE_APP_TODOK_SITE
  }

  static get getAppVersion() {
    return packageJson.version
  }

  static isTesting() {
    return 'production' !== this.currentEnv
  }

  /**
   * Auth0の設定。
   * CallbackUrlは、現在の実行環境（ブラウザ）に基づきルートページのURLを返す
   */
  static get auth0Config() {
    const conf = {
      domain: env.VUE_APP_AUTH0_DOMAIN,
      clientId: env.VUE_APP_AUTH0_CLIENT_ID,
      audience: env.VUE_APP_AUTH0_AUDIENCE,
      redirectUri: window.location.origin,
    }
    return conf
  }
}
