<template>
  <div>
    <v-row v-if="inputTitle !== ''">
      <v-col class="pt-4">
        <common-input-title :title="inputTitle" :requiredFlag="requiredFlag" :caption="caption" />
      </v-col>
    </v-row>
    <v-radio-group class="ml-3 mt-1" v-model="selectedValue" :error="hasError" :disabled="disabled" column hide-details>
      <v-radio color="memberid-primary" class="mt-3 mb-3" v-for="(text, i) in placementTextList" :key="i" :value="i">
        <template v-slot:label>
          <div v-bind:class="[disabled === true ? 'memberid-black-400--text' : 'memberid-black-600--text']">
            {{ text }}
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <v-row v-if="hasError">
      <v-col>
        <error-messages :isShow="hasError" :messages="errorMessages"></error-messages>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessages from '@/components/ErrorMessageListComponent'
export default {
  components: { CommonInputTitle, ErrorMessages },
  props: {
    placeholder: { type: String },
    value: { type: Number },
    errorMessages: { type: Array },
    disabled: { type: Boolean },
    inputTitle: { type: String },
    caption: { type: String },
    requiredFlag: { type: Boolean },
    placementTextList: { type: Array },
  },
  computed: {
    hasError: function() {
      return typeof this.errorMessages !== 'undefined' && this.errorMessages.length > 0
    },

    selectedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
  },
}
</script>
