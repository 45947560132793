export default {
  data() {
    return {
      isMobile: false,
      breakPoint: 960,
    }
  },
  mounted: function() {
    this.onResize()
  },
  methods: {
    onResize() {
      if (window.innerWidth < this.breakPoint) this.isMobile = true
      else this.isMobile = false
    },
    moveLink(url) {
      window.open(url, '_blank')
    },
  },
}
