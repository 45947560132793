import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
Vue.use(Vuetify)

// FIXME テーマカスタマイズ
// see https://vuetifyjs.com/ja/customization/theme/
// see https://github.com/coop-sapporo/memberid-frontend/issues/10
const memberidTheme = {
  'memberid-primary': '#00A23E',
  'memberid-secondary': '#5C370C',
  'memberid-secondary-dark': '#1F8446',
  'memberid-tertiary': '#F5F1E0',
  'memberid-black-600': '#00220D',
  'memberid-black-500': '#383E3A',
  'memberid-black-400': '#77837B',
  'memberid-black-300': '#BDC6C0',
  'memberid-black-200': '#E1E3E1',
  'memberid-black-100': '#F3F3F3',
  'memberid-white': '#FFFFFF',
  'memberid-red': '#DF281D',
}

const opts = {
  icons: {
    iconfont: 'mdi',
  },
  theme: { themes: { light: memberidTheme } },
}

export default new Vuetify(opts)
