<template>
  <v-snackbar absolute class="pt-16" :timeout="timeout()" top :right="!isMobile" v-model="enable" v-if="errorMode">
    <div class="font-weight-bold">{{ title }}</div>
    <a v-if="link !== ''" :href="link" class="memberid-white--text">
      <div v-if="message !== ''">
        {{ message }}
      </div>
    </a>
    <div v-else>
      <div v-if="message !== ''">
        {{ message }}
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn color="memberid-white " class="font-weight-bold" text v-bind="attrs" @click="closeSnack()">
        閉じる
      </v-btn>
    </template>
  </v-snackbar>
  <v-snackbar
    absolute
    color="memberid-white "
    class="pt-16"
    :timeout="timeout()"
    top
    :right="!isMobile"
    v-model="enable"
    v-else
  >
    <div class="font-weight-bold memberid-black-400--text" v-if="message !== ''">
      <v-icon class="pr-2 memberid-green--text">mdi-check-circle-outline</v-icon>{{ message }}
    </div>
  </v-snackbar>
</template>
<script>
import CommonMixin from '@/components/mixins/common-mixin'

export default {
  name: 'Snackbar',
  mixins: [CommonMixin],
  props: {
    enable: { type: Boolean, required: true, default: false },
    title: { type: String, required: true, default: '' },
    message: { type: String, required: false, default: '' },
    link: { type: String, required: false, default: '' },
    errorMode: { type: Boolean, required: false, default: true },
  },
  methods: {
    timeout() {
      if (this.link === '') {
        return 6000
      }
      return -1
    },
    closeSnack() {
      this.enable = false
      this.title = ''
      this.message = ''
      this.link = ''
    },
  },
}
</script>
