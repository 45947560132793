<template>
  <div>
    <v-row>
      <v-col class="pt-6">
        <common-input-title :title="inputTitle" :requiredFlag="requiredFlag" :caption="caption" />
      </v-col>
    </v-row>
    <v-row align="center" class="mt-0">
      <v-col cols="12" class="pt-0 pb-0">
        <v-select
          outlined
          hide-details
          background-color="memberid-white"
          color="memberid-primary"
          item-color="memberid-primary"
          :placeholder="placeholder"
          :items="items"
          v-model="inputValue"
          :disabled="isDisabled"
          :error="hasError"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="my-1">
      <v-col>
        <error-messages :isShow="hasError" :messages="errorMessages"></error-messages>
      </v-col>
    </v-row>
    <div class="pb-4 memberid-black-500--text subtitle-2" v-if="hasMessageSlot">
      <slot name="message">slot</slot>
    </div>
    <div class="pb-4 memberid-black-500--text subtitle-2" v-else-if="messages !== null">
      <div v-for="(message, key) in messages" :key="key">{{ message }}</div>
    </div>
  </div>
</template>
<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessages from '@/components/ErrorMessageListComponent'
export default {
  components: { CommonInputTitle, ErrorMessages },
  name: 'SelectComponent',
  props: {
    inputTitle: { type: String },
    caption: { type: String },
    requiredFlag: { type: Boolean },
    messages: { type: Array },
    placeholder: { type: String },
    value: { type: String },
    isDisabled: { type: Boolean },
    errorMessages: { type: Array },
    items: { type: Array },
  },
  computed: {
    hasError: function() {
      return typeof this.errorMessages !== 'undefined' && this.errorMessages.length > 0
    },

    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      },
    },
    hasMessageSlot() {
      return Boolean(this.$slots.message)
    },
  },
}
</script>
