// import apiClientBase from '@/api/api-client-base'
// import GetMemberRequest from '@/api/apimodel/get-member-request'
// import GetMemberAddInfoRequest from '@/api/apimodel/get-member-add-info-request'
// import GetPlanningWeeksRequest from '@/api/apimodel/get-planning-weeks-request'
// import GetPointBalanceRequest from '@/api/apimodel/get-point-balance-request'
// import SendMailRequest from '@/api/apimodel/send-mail-request'
// import memberidApiClient from '@/api/memberid-api-client'
import DeliveryInformation from '@/models/delivery-information'
// // import Config from '@/config'
import storage from '@/storage'
import store from '@/store'
// import router from '@/router'
// import Vue from 'vue'
import MutationTypes from '@/store/mutation-types'

const state = {
  deliveryInformation: new DeliveryInformation(),
  hasDeliveryData: false,
}

const getters = {
  deliveryInformation: state => state.deliveryInformation,
  hasDeliveryData: state => state.hasDeliveryData,
}

const mutations = {
  [MutationTypes.SET_DELIVERY_INFORMATION](state, { deliveryInformation }) {
    state.deliveryInformation = deliveryInformation
    state.hasDeliveryData = true
  },
}

const actions = {
  setDeliveryInformation({ commit }, { val }) {
    commit(MutationTypes.SET_DELIVERY_INFORMATION, { deliveryInformation: val })
    storage.setDeliveryInfo(val)
  },

  // f配達情報
  getDeliveryInformation({ commit }) {
    const deliveryInfo = store.getters.deliveryInformation
    if (!store.getters.hasDeliveryData) {
      const stg = storage.getDeliveryInfo()
      if (stg) {
        const di = new DeliveryInformation()

        di.selectedAddress = stg.selectedAddress
        di.zipCode = stg.zipCode
        di.addressList = stg.addressList
        di.address1 = stg.address1
        di.address2 = stg.address2
        di.address3 = stg.address3
        di.selectedTel = stg.selectedTel
        di.phoneNumber = stg.phoneNumber
        di.selectedResidence = stg.selectedResidence
        di.selectedPlacement = stg.selectedPlacement
        di.placementOtherInput = stg.placementOtherInput
        di.selectedConfirmationOfDelivery = stg.selectedConfirmationOfDelivery
        di.selectedDeliveryOfPaperCatalog = stg.selectedDeliveryOfPaperCatalog
        di.selectedTodokTrialCampaign = stg.selectedTodokTrialCampaign
        di.selectedIntroducer = stg.selectedIntroducer
        di.introducerName = stg.introducerName
        di.introducerUnionNumber = stg.introducerUnionNumber
        di.introducerPhoneNumber = stg.introducerPhoneNumber
        di.email = stg.email
        commit(MutationTypes.SET_DELIVERY_INFORMATION, { deliveryInformation: di })
        return di
      }
    }
    return deliveryInfo
  },
  hasDelivery() {
    return storage.getDeliveryInfo() !== null
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
