// import order from '@/store/modules/order'
// import product from '@/store/modules/product'
import Vue from 'vue'
import Vuex from 'vuex'
// import auth from './modules/auth'
import base from './modules/base'
import member from './modules/member'
import delivery from './modules/delivery-information'
import child from './modules/child-information'
import capital from './modules/capital'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: { base, member, delivery, child, capital },
})

export default store
