import { AsYouType } from 'libphonenumber-js'

export default class StringUtil {
  static formatPhoneNumber(str) {
    return new AsYouType('JP').input(str)
  }
  static stringMemberCode(memberCode) {
    return memberCode.padStart(14, '0')
  }

  static calcCheckDigit2(value) {
    if (value == null || value.length != 12) {
      return null
    }

    const intArray = value.split('').map(x => parseInt(x))

    let s = 0
    const c = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    for (let i = 0; i < intArray.length; i++) {
      s += intArray[i] * c[i]
    }
    let t = parseInt(s / 11, 10)
    let r = s - t * 11
    if (r == 0) {
      return 0
    }
    let cdv = parseInt(11 - r, 10)
    let ret = parseInt(cdv % 10, 10)
    return ret
  }

  static zenkana2Hankana(str) {
    var kanaMap = {
      ガ: 'ｶﾞ',
      ギ: 'ｷﾞ',
      グ: 'ｸﾞ',
      ゲ: 'ｹﾞ',
      ゴ: 'ｺﾞ',
      ザ: 'ｻﾞ',
      ジ: 'ｼﾞ',
      ズ: 'ｽﾞ',
      ゼ: 'ｾﾞ',
      ゾ: 'ｿﾞ',
      ダ: 'ﾀﾞ',
      ヂ: 'ﾁﾞ',
      ヅ: 'ﾂﾞ',
      デ: 'ﾃﾞ',
      ド: 'ﾄﾞ',
      バ: 'ﾊﾞ',
      ビ: 'ﾋﾞ',
      ブ: 'ﾌﾞ',
      ベ: 'ﾍﾞ',
      ボ: 'ﾎﾞ',
      パ: 'ﾊﾟ',
      ピ: 'ﾋﾟ',
      プ: 'ﾌﾟ',
      ペ: 'ﾍﾟ',
      ポ: 'ﾎﾟ',
      ヴ: 'ｳﾞ',
      ヷ: 'ﾜﾞ',
      ヺ: 'ｦﾞ',
      ア: 'ｱ',
      イ: 'ｲ',
      ウ: 'ｳ',
      エ: 'ｴ',
      オ: 'ｵ',
      カ: 'ｶ',
      キ: 'ｷ',
      ク: 'ｸ',
      ケ: 'ｹ',
      コ: 'ｺ',
      サ: 'ｻ',
      シ: 'ｼ',
      ス: 'ｽ',
      セ: 'ｾ',
      ソ: 'ｿ',
      タ: 'ﾀ',
      チ: 'ﾁ',
      ツ: 'ﾂ',
      テ: 'ﾃ',
      ト: 'ﾄ',
      ナ: 'ﾅ',
      ニ: 'ﾆ',
      ヌ: 'ﾇ',
      ネ: 'ﾈ',
      ノ: 'ﾉ',
      ハ: 'ﾊ',
      ヒ: 'ﾋ',
      フ: 'ﾌ',
      ヘ: 'ﾍ',
      ホ: 'ﾎ',
      マ: 'ﾏ',
      ミ: 'ﾐ',
      ム: 'ﾑ',
      メ: 'ﾒ',
      モ: 'ﾓ',
      ヤ: 'ﾔ',
      ユ: 'ﾕ',
      ヨ: 'ﾖ',
      ラ: 'ﾗ',
      リ: 'ﾘ',
      ル: 'ﾙ',
      レ: 'ﾚ',
      ロ: 'ﾛ',
      ワ: 'ﾜ',
      ヲ: 'ｦ',
      ン: 'ﾝ',
      ァ: 'ｧ',
      ィ: 'ｨ',
      ゥ: 'ｩ',
      ェ: 'ｪ',
      ォ: 'ｫ',
      ッ: 'ｯ',
      ャ: 'ｬ',
      ュ: 'ｭ',
      ョ: 'ｮ',
      '。': '｡',
      '、': '､',
      ー: 'ｰ',
      '「': '｢',
      '」': '｣',
      '・': '･',
    }
    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g')
    return str
      .replace(reg, function(match) {
        return kanaMap[match]
      })
      .replace(/゛/g, 'ﾞ')
      .replace(/゜/g, 'ﾟ')
  }
}
