/**
 * エラーメッセージ
 */
export default class ErrorMessage {
  code = null
  title = null
  message = null
  link = null

  static isRegistrationError(errorStatus) {
    return 'CSP17004' === errorStatus
  }

  static fromObject(item) {
    let e = new ErrorMessage()
    e.code = item.code
    e.title = item.title
    e.message = item.message
    e.link = item.link
    return e
  }
}
