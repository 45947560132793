<template>
  <div class="pb-10 pa-3" :class="{ 'xs-wrap': !isMobile, 'mt-2': !isMobile }" v-resize="onResize">
    <v-container class="mt-1">
      <h3 class="memberid-black-600--text title font-weight-bold">宅配で利用する口座を登録しました</h3>
      <div class="caption mt-8 ma-2">
        ※金融機関とのネットワーク不備などで口座登録のエラーが発生する場合もございます。その場合、コープさっぽろよりご連絡いたします。
      </div>
    </v-container>
    <rounded-button buttonText="次へ" :nextPage="nextPage" :isdisabled="false" />
  </div>
</template>
<script>
import RoundedButton from '@/components/RoundedButtonComponent'
import storage from '@/storage'
import store from '@/store'
import CommonMixin from '@/components/mixins/common-mixin'

export default {
  name: 'AccountTransferComplete',
  mixins: [CommonMixin],
  components: {
    RoundedButton,
  },
  data() {
    return {}
  },
  async mounted() {
    storage.setAccountTransferStatus(true)
    store.dispatch('routerPushWithFromQuery', {
      name: 'MemberConfirm',
    })
  },
  methods: {
    nextPage() {
      store.dispatch('routerPushWithFromQuery', { name: 'MemberConfirm' })
    },
  },
}
</script>
