<template>
  <div>
    <v-container class="mt-1"> </v-container>
    <v-dialog persistent v-model="ieDialog" width="500">
      <v-card class="pa-4">
        <h4 class="memberid-black-600--text subtitle-1 font-weight-bold mb-2">
          お使いのブラウザはサポートされていません
        </h4>
        <p class="memberid-black-400--text subtitle-2 mb-10">
          大変申し訳ございませんが、現在ご利用のブラウザはコープさっぽろの組合員加入ではサポートしておりません。<br />
          安全にサービスをご利用いただくために対応するブラウザをお使いください。
        </p>
        <v-layout justify-center class="mb-6">
          <v-btn
            @click="
              ieDialog = false
              historyBack()
            "
            height="52"
            width="90%"
            class="memberid-primary rounded-pill memberid-white--text font-weight-bold subtitle-1"
            >戻る</v-btn
          >
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Config from '@/config'
// import Auth0Constants from '@/lib/auth0-constants'
import storage from '@/storage'
// import LoginTokenRequest from '@/api/apimodel/login-token-request'
import store from '@/store'
import BrowserUtils from '@/lib/browser-utils'
import Vue from 'vue'
import memberidApiClient from '@/api/memberid-api-client'

export default {
  name: 'MenmberEntrance',
  data() {
    return {
      ieDialog: false,
    }
  },
  async mounted() {
    Vue.$startLoading()
    let isIe = BrowserUtils.isIE()
    if (isIe) {
      Vue.$endLoading()
      this.ieDialog = isIe
      return
    }
    const isMaintenance = await this.checkMaintenace()
    if (isMaintenance) {
      Vue.$endLoading()
      store.dispatch('routerPushWithFromQuery', { name: 'Maintenance' })
      return
    }

    // 入り口から入ってきたら全部消す
    storage.allClear()
    storage.allExtraData()
    Vue.$endLoading()

    store.dispatch('routerPushWithFromQuery', { name: 'MemberEntry' })
  },
  methods: {
    historyBack() {
      window.history.back(-1)
      return false
    },
    async checkMaintenace() {
      const response = await memberidApiClient.getMaintenanceInfo().catch((error) => {
        // console.log('getMaintenanceInfo error')
        console.log(error)
      })
      if (response) {
        const maintenanceInfo = response.data.maintenanceInfo
        if (maintenanceInfo && maintenanceInfo.maintenanceDateTime && maintenanceInfo.description) {
          return true
        }
        return false
      }
      return false
    },
  },
  computed: {
    // getEnvName() {
    //   return Config.getEnvName
    // },
  },
}
</script>

<style scoped>
.union-description {
  text-align: center;
}
.union-description-text {
  color: #000000de;
}
</style>

<style>
.memberid-header-bottom {
  border-bottom: 1px solid #cfcfcf;
}
</style>
