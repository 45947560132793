import imageCompression from 'browser-image-compression'

export default class ImageUtil {
  // アップロードされた画像ファイルを取得
  static async getCompressImageFileAsync(file) {
    const options = {
      maxWidthOrHeight: 800, // 最大画像幅もしくは高さ
    }
    try {
      // 圧縮画像の生成
      return await imageCompression(file, options)
    } catch (error) {
      console.error('getCompressImageFileAsync is error', error)
      throw error
    }
  }
  // プレビュー表示用のdataUrlを取得
  static async getDataUrlFromFile(file) {
    try {
      return await imageCompression.getDataUrlFromFile(file)
    } catch (error) {
      console.error('getDataUrlFromFile is error', error)
      throw error
    }
  }
}
