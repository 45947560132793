export default class Member {
  firstName = ''
  lastName = ''
  firstNameKana = ''
  lastNameKana = ''
  birthDate = null
  sex = null
  zipCode = ''
  address1 = ''
  address2 = ''
  address3 = ''
  phoneNumber = ''
  email = ''

  // needsSalesInfo = true
  selectedChildService = null
  selectedDelivery = null

  addressList = []

  get fullName() {
    return this.lastName + this.firstName
  }

  get fullNameKana() {
    return this.lastNameKana + this.firstNameKana
  }

  formatZipCode() {
    if (this.zipCode.length === 0) return
    return this.zipCode.slice(0, 3) + '-' + this.zipCode.slice(3)
  }

  formatAddress() {
    if (this.address1.length === 0) return
    return this.address1 + '　' + this.address2 + '　' + this.address3
  }
}
