import AbstractApiRequest from '@/api/apimodel/abstract-api-request'

export default class ReserveMemberDeliveryRequest extends AbstractApiRequest {
  unionNumber = ''
  unionLastName = ''
  unionFirstName = ''
  unionBirthDate = ''
  unionZipCode = ''
  unionAddress1 = ''
  unionAddress2 = ''
  unionAddress3 = ''
  unionPhoneNumber = ''
  zipCode = ''
  address1 = ''
  address2 = ''
  address3 = ''
  phoneNumber = ''
  houseType = null
  placementType = null
  placementOtherInput = ''
  intercomType = null
  distributePaperCatalogs = null
  requestTodokTrialCampaign = null
  childType = null
  childName = ''
  childDate = ''
  image = ''
  introducerName = ''
  introducerUnionNumber = ''
  introducerPhoneNumber = ''
  termsOfUseFlag = null
  email = ''
  from = ''
}
