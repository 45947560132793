// import apiClientBase from '@/api/api-client-base'
// import GetMemberRequest from '@/api/apimodel/get-member-request'
// import GetMemberAddInfoRequest from '@/api/apimodel/get-member-add-info-request'
// import GetPlanningWeeksRequest from '@/api/apimodel/get-planning-weeks-request'
// import GetPointBalanceRequest from '@/api/apimodel/get-point-balance-request'
// import SendMailRequest from '@/api/apimodel/send-mail-request'
// import memberidApiClient from '@/api/memberid-api-client'
import ChildInformation from '@/models/child-information'
// // import Config from '@/config'
import storage from '@/storage'
import store from '@/store'
// import router from '@/router'
// import Vue from 'vue'
import MutationTypes from '@/store/mutation-types'

const state = {
  childInformation: new ChildInformation(),
  hasChildData: false,
}

const getters = {
  childInformation: state => state.childInformation,
  hasChildData: state => state.hasChildData,
}

const mutations = {
  [MutationTypes.SET_CHILD_INFORMATION](state, { childInformation }) {
    state.childInformation = childInformation
    state.hasChildData = true
  },
}

const actions = {
  setChildInformation({ commit }, { val }) {
    commit(MutationTypes.SET_CHILD_INFORMATION, { childInformation: val })
    storage.setChildInfo(val)
  },

  // f配達情報
  getChildInformation({ commit }) {
    const childInfo = store.getters.childInformation
    if (!store.getters.hasChildData) {
      const stg = storage.getChildInfo()
      if (stg) {
        const ci = new ChildInformation()

        ci.selectedAboutChildren = stg.selectedAboutChildren
        ci.childDate = stg.childDate
        ci.image = stg.image
        ci.childName = stg.childName
        commit(MutationTypes.SET_CHILD_INFORMATION, { childInformation: ci })
        return ci
      }
    }
    return childInfo
  },

  hasChild() {
    return storage.getChildInfo() !== null
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
