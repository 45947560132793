<template>
  <v-overlay :value="loading" z-index="1000">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>
<script>
export default {
  name: 'LoadingOverlay',
  props: { loading: { type: Boolean, required: true, default: false } },
}
</script>
