<template>
  <div class="mb-6">
    <v-row>
      <v-col class="pt-6">
        <common-input-title :title="inputTitle" :requiredFlag="requiredFlag" :caption="caption" />
      </v-col>
    </v-row>
    <v-row align="center" class="mt-0 mb-1">
      <v-col cols="6" class="pt-2">
        <v-text-field
          v-model="inputValueLeft"
          color="memberid-primary"
          background-color="memberid-white"
          :placeholder="placeholderLeft"
          :error="leftErrorMessage != '' || bothErrorMessage != ''"
          :disabled="disabledFlag"
          @blur="$emit('blurLeft', $event)"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="pt-2 ">
        <v-text-field
          v-model="inputValueRight"
          color="memberid-primary"
          background-color="memberid-white"
          :placeholder="placeholderRight"
          :error="rightErrorMessage != '' || bothErrorMessage != ''"
          :disabled="disabledFlag"
          @blur="$emit('blurRight', $event)"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <error-message :isShow="leftErrorMessage != ''" :message="leftErrorMessage"></error-message>
    <error-message :isShow="rightErrorMessage != ''" :message="rightErrorMessage"></error-message>
    <error-message :isShow="bothErrorMessage != ''" :message="bothErrorMessage"></error-message>
  </div>
</template>

<script>
import CommonInputTitle from '@/components/InputTitle'
import ErrorMessage from '@/components/ErrorMessageComponent'
export default {
  components: { CommonInputTitle, ErrorMessage },
  props: {
    placeholderLeft: { type: String },
    placeholderRight: { type: String },
    valueLeft: { type: String },
    valueRight: { type: String },
    disabledFlag: { type: Boolean },
    inputTitle: { type: String },
    caption: { type: String },
    requiredFlag: { type: Boolean },
    leftErrorMessage: { type: String },
    rightErrorMessage: { type: String },
    bothErrorMessage: { type: String },
  },
  computed: {
    hasError: function() {
      return typeof this.errorMessages !== 'undefined' && this.errorMessages.length > 0
    },
    inputValueLeft: {
      get() {
        return this.valueLeft
      },
      set(value) {
        this.$emit('update:valueLeft', value)
      },
    },
    inputValueRight: {
      get() {
        return this.valueRight
      },
      set(value) {
        this.$emit('update:valueRight', value)
      },
    },
  },
}
</script>
